@charset "UTF-8";
/* -----------------------------------------------------
+
+ FRONT-MAN.com
+
------------------------------------------------------ */
@font-face {
  font-family: "Avenir Next Bold";
  font-display: block;
  src: url("../fonts/AvenirNextLTPro-Bold.eot");
  src: local("Avenir Next LT Pro Bold"), local("AvenirNextLTPro-Bold"), url("../fonts/AvenirNextLTPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Bold.ttf") format("truetype"), url("../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Demi";
  font-display: block;
  src: url("../fonts/AvenirNextLTPro-Demi.eot");
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"), url("../fonts/AvenirNextLTPro-Demi.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Demi.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Demi.ttf") format("truetype"), url("../fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Regular";
  font-display: block;
  src: url("../fonts/AvenirNextLTPro-Regular.eot");
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"), url("../fonts/AvenirNextLTPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Regular.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Regular.ttf") format("truetype"), url("../fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Medium";
  font-display: block;
  src: url("../fonts/AvenirNextLTPro-Medium.eot");
  src: local("Avenir Next LT Pro Medium"), local("AvenirNextLTPro-Medium"), url("../fonts/AvenirNextLTPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-Medium.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Medium.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-Medium.ttf") format("truetype"), url("../fonts/AvenirNextLTPro-Medium.svg#AvenirNextLTPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next MediumIt";
  font-display: block;
  src: url("../fonts/AvenirNextLTPro-MediumIt.eot");
  src: local("Avenir Next LT Pro MediumIt"), local("AvenirNextLTPro-MediumIt"), url("../fonts/AvenirNextLTPro-MediumIt.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirNextLTPro-MediumIt.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-MediumIt.woff") format("woff"),
    url("../fonts/AvenirNextLTPro-MediumIt.ttf") format("truetype"), url("../fonts/AvenirNextLTPro-MediumIt.svg#AvenirNextLTPro-MediumIt") format("svg");
  font-weight: normal;
  font-style: italic;
}
/*------------------------------------*\
    @BASE #PAGE
\*------------------------------------*/
html {
  min-height: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Avenir Next Medium", sans-serif;
  font-size: 18px;
  line-height: 1.375;
  font-weight: 400;
  background-color: #fff;
  color: #707070;
  height: 100%;
  min-width: 320px;
  position: relative;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
body * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0;
}

::-moz-selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

/*------------------------------------*\
    @BASE #TYPE
\*------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  color: #707070;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p:last-child {
  margin-bottom: 0;
}

dl dt {
  font-weight: 400;
}
dl dd {
  margin-bottom: 0;
}

p:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 560px) {
  p:not(:last-child) {
    margin-bottom: 20px;
  }
}

hr {
  margin: 40px 0;
}

strong {
  font-weight: 700;
}

/*------------------------------------*\
    @BASE #LINKS
\*------------------------------------*/
a {
  outline: none !important;
  /* removing outline in Mozilla */
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
a:hover,
a:focus {
  text-decoration: none;
}
p a {
  text-decoration: underline;
  color: #707070;
}
p a:hover {
  color: #4245c8;
  text-decoration: underline;
}

/*------------------------------------*\
    @OBJECTS #BUTTONS
\*------------------------------------*/
button,
button:focus,
button:active {
  outline: none !important;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
button:not(.btn),
button:focus:not(.btn),
button:active:not(.btn) {
  border: none;
}

.btn {
  cursor: pointer;
  font-family: "Avenir Next Demi", sans-serif;
}

*[class*="btn-outline-"] {
  border-width: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*------------------------------------*\
    @OBJECTS #ICONS
\*------------------------------------*/
.icon {
  fill: currentColor;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/*------------------------------------*\
    @COMPONENTS #BUTTONS
\*------------------------------------*/
.btn--full {
  width: 100%;
}

@media (max-width: 560px) {
  .btn--full-xs {
    width: 100%;
  }
}

.btn--primary,
.btn--primary:focus {
  background-color: #4245c8;
  color: #fff;
}
.btn--primary:hover,
.btn--primary:focus:hover {
  background-color: #3033a7;
}

.btn--alt,
.btn--alt:focus {
  background-color: #8bc73b;
  color: #fff !important;
}
.btn--alt:hover,
.btn--alt:focus:hover {
  background-color: #70a12e;
}

.btn--arrow {
  position: relative;
  padding: 15px 80px 15px 20px;
  text-align: left;
  font-size: 20px;
}
.btn--arrow .icon {
  width: 48px;
  height: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--arrow .icon-arrow-right {
  right: 20px;
}
@media (max-width: 560px) {
  .btn--arrow .icon-arrow-right {
    right: 10px;
  }
}
.btn--arrow .icon-arrow-left {
  left: 20px;
}
@media (max-width: 560px) {
  .btn--arrow .icon-arrow-left {
    left: 10px;
  }
}
.btn--arrow:hover .icon-arrow-right {
  right: 12px;
}
@media (max-width: 560px) {
  .btn--arrow:hover .icon-arrow-right {
    right: 0;
  }
}
.btn--arrow:hover .icon-arrow-left {
  left: 12px;
}
@media (max-width: 560px) {
  .btn--arrow:hover .icon-arrow-left {
    left: 0;
  }
}
@media (max-width: 992px) {
  .btn--arrow {
    font-size: 18px;
  }
}

.btn--arrow-left {
  padding-right: 20px;
  padding-left: 160px;
  text-align: right;
}

/*------------------------------------*\
    @COMPONENTS #FORMS
\*------------------------------------*/
input,
.form-control {
  -webkit-appearance: none;
  /* removing inner shadow on iOS inputs */
  -webkit-font-smoothing: inherit;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "Avenir Next Medium", sans-serif;
  font-size: 22px;
  padding: 15px 0 !important;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 0;
  /*PLACEHOLDER*/
}
@media (max-width: 768px) {
  input,
  .form-control {
    font-size: 20px;
  }
}
@media (max-width: 560px) {
  input,
  .form-control {
    font-size: 16px;
  }
}
input:hover,
input:focus,
.form-control:hover,
.form-control:focus {
  border-color: #4245c8;
}
input:invalid,
.form-control:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
  /* fixes mozilla red outline (shadow) */
}
input[readonly],
.form-control[readonly] {
  cursor: text;
}
input::-ms-clear,
.form-control::-ms-clear {
  display: none;
  /* remove IE clear icon */
}
input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #999;
}
input:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
  opacity: 1;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
  opacity: 1;
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control[disabled] {
  background-color: #fff;
}

.form-group--valid .form-control {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjUuMSAxOC4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNS4xIDE4LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojOEJDNjNGO308L3N0eWxlPjxnPjxwb2x5Z29uIGlkPSJYTUxJRF8zXyIgY2xhc3M9InN0MCIgcG9pbnRzPSI5LjEsMTguMiAwLDkuMSAyLjEsNyA5LjEsMTMuOSAyMywwIDI1LjEsMi4xICIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-size: 25px 18px;
  background-position: 100% 50%;
}
@media (max-width: 560px) {
  .form-group--valid .form-control {
    background-size: 19px 14px;
  }
}

.form-group--error {
  margin-bottom: 30px;
}
.form-group--error .form-control {
  border-color: #e8425e;
}
.form-group--error label {
  color: #e8425e !important;
}
@media (max-width: 560px) {
  .form-group--error {
    margin-bottom: 40px !important;
  }
}

.form-group {
  position: relative;
  margin-bottom: 40px;
}
.form-group::after {
  display: block;
  clear: both;
  content: "";
}
.form-group:last-child {
  margin-bottom: 0;
}

label {
  font-weight: normal;
  font-family: "Avenir Next Medium", sans-serif;
}
label a {
  text-decoration: underline;
}
label a,
label a:focus {
  color: #4245c8;
}
label a:hover,
label a:focus:hover {
  color: #8bc73b;
}

.label-flat {
  color: #aaa;
  text-align: left;
  display: block;
  cursor: default;
  margin-bottom: 20px;
  font-size: 15px;
}

.form-hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/*------------------------------------*\
    @COMPONENTS #FORMS-LABELED
\*------------------------------------*/
.form-labeled {
  position: relative;
  margin-bottom: 40px !important;
}
.form-labeled:after {
  content: "";
  width: 0;
  height: 2px;
  display: block;
  background-color: #4245c8;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.form-labeled label {
  font-family: "Avenir Next Medium", sans-serif;
  color: #999;
  font-weight: normal;
  font-size: 22px;
  cursor: text;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.form-labeled label *[data-toggle="tooltip"] .icon {
  pointer-events: none;
}
.form-labeled input:hover,
.form-labeled input:focus {
  border-color: #ddd;
}
.form-labeled.focus label,
.form-labeled.form-labeled--focused label,
.form-labeled.active label {
  font-size: 14px;
  top: -15px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  cursor: default;
}
@media (max-width: 560px) {
  .form-labeled.focus label,
  .form-labeled.form-labeled--focused label,
  .form-labeled.active label {
    font-size: 12px;
    top: -10px;
  }
}
.form-labeled + .btn {
  margin-top: 10px;
}
.form-labeled.focus:after {
  width: 100%;
}
@media (max-width: 768px) {
  .form-labeled {
    margin-bottom: 30px !important;
  }
  .form-labeled label {
    font-size: 20px;
  }
}
@media (max-width: 560px) {
  .form-labeled {
    margin-bottom: 15px !important;
  }
  .form-labeled.form-group--error {
    margin-bottom: 40px !important;
  }
  .form-labeled label {
    font-size: 16px;
  }
  .form-labeled + .btn {
    margin-top: 0;
  }
}

@media (min-width: 561px) {
  .form-group--xs {
    margin-bottom: 0 !important;
  }
}

.required {
  padding-right: 10px;
}
.required:after {
  content: " *";
  color: #e8425e;
}

.hideShowPassword-wrapper {
  width: 100% !important;
}

.hideShowPassword-toggle {
  font-size: 16px;
  text-transform: uppercase;
  color: #444;
  background-color: transparent;
  font-family: "Avenir Next Demi", sans-serif;
  padding: 0;
  line-height: 1;
  height: auto;
  margin-top: 0;
}

/*------------------------------------*\
	@COMPONENTS #PAGE
\*------------------------------------*/
.menu-on {
  overflow: hidden;
}

.page {
  -webkit-box-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 100px;
}
.page.page--home {
  padding-top: 0;
}
@media (max-width: 767px) {
  .page {
    padding-top: 90px;
  }
}
@media (max-width: 560px) {
  .page {
    padding-top: 70px;
  }
}

.main {
  position: relative;
}

.main__header,
.main__footer {
  padding: 40px 0;
  border-bottom: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 560px) {
  .main__header,
  .main__footer {
    padding: 25px 0;
  }
}

.main__footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main__title {
  color: #4245c8;
  font-size: 32px;
  margin: 0;
}
@media (max-width: 767px) {
  .main__title {
    font-size: 24px;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
}

/*------------------------------------*\
	@COMPONENTS #HEADER
\*------------------------------------*/
.header {
  -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header .container {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 768px) {
  .header .container {
    height: 90px;
  }
}
@media (max-width: 560px) {
  .header .container {
    height: 70px;
  }
}

@media (min-width: 992px) {
  .header--top {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header--top .header__nav {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
  }
}

.header__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__nav li:not(:last-child) {
  margin-right: 50px;
}
.header__nav a:not(.btn) {
  color: #707070;
}
.header__nav a:not(.btn):hover {
  color: #8bc73b;
}
@media (max-width: 560px) {
  .header__nav {
    display: none;
  }
}

@media (max-width: 768px) {
  .header__item {
    display: none;
  }
}

/*------------------------------------*\
	@COMPONENTS #HEADER
\*------------------------------------*/
.logo-wrap {
  width: 170px;
  height: 34px;
  position: relative;
}
.logo-wrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 560px) {
  .logo-wrap {
    width: 142px;
    height: 30px;
  }
}
.logo-wrap .logo--color {
  opacity: 1;
}
@media (min-width: 992px) {
  .header--top .logo-wrap .logo--white {
    opacity: 1;
  }
}

/*------------------------------------*\
	@COMPONENTS #MENU
\*------------------------------------*/
.menu-trigger {
  padding: 0;
  background-color: transparent;
  width: 36px;
  height: 36px;
  position: relative;
  color: #707070;
}
.menu-trigger .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 36px;
  height: 24px;
}
.menu-trigger:hover {
  color: #8bc73b;
}
@media (min-width: 769px) {
  .menu-trigger {
    display: none;
  }
}
@media (max-width: 560px) {
  .menu-trigger .icon {
    width: 30px;
    height: 20px;
  }
}

.menu-overlay {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;
  z-index: 1000;
  -webkit-transform: translateX(-100vw);
  -ms-transform: translateX(-100vw);
  transform: translateX(-100vw);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
.menu-on .menu-overlay {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
@media (max-width: 560px) {
  .menu-overlay {
    display: none;
  }
}

.menu {
  background-color: rgba(46, 55, 73, 0.95);
  color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  color: #fff;
  padding: 30px;
  -webkit-transform: translateX(100vw);
  -ms-transform: translateX(100vw);
  transform: translateX(100vw);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 1001;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-y: auto;
}
.menu-on .menu {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
@media (max-width: 560px) {
  .menu {
    left: 0;
  }
}

.menu__close {
  background-color: transparent;
  padding: 0;
  color: #fff;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.menu__close .icon {
  width: 22px;
  height: 22px;
}

.menu__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 26px;
  margin: 30px 0;
}
.menu__nav li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.menu__nav li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.menu__nav a {
  display: block;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
}

/*------------------------------------*\
	@COMPONENTS #FOOTER
\*------------------------------------*/
.footer {
  background-color: #2e3749;
  padding: 20px 0 70px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
@media (max-width: 768px) {
  .footer {
    padding: 20px 0 50px 0;
  }
}
@media (max-width: 560px) {
  .footer {
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .footer .d-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer__copy > * {
  display: inline-block;
}
.footer__copy > *:not(:last-child) {
  margin-right: 30px;
}
.footer__copy a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.footer__copy a:hover {
  color: #fff !important;
}
@media (max-width: 768px) {
  .footer__copy span {
    display: block;
    margin-bottom: 10px;
  }
}

.footer__brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  min-width: 80px;
}
.footer__brand:before {
  content: "";
  width: 80px;
  height: 1px;
  display: block;
  background-color: #fff;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 560px) {
  .footer__brand {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }
  .footer__brand:before {
    display: none;
  }
}

.footer__logo {
  width: 185px;
  height: 37px;
}
@media (max-width: 560px) {
  .footer__logo {
    width: 124px;
    height: 25px;
  }
}

/*------------------------------------*\
	@COMPONENTS #COVER
\*------------------------------------*/
.cover {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cover > * {
  width: 50%;
}
@media (max-width: 767px) {
  .cover {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    text-align: center;
  }
  .cover > * {
    width: 100%;
  }
}

.cover--alt {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.cover__img {
  position: relative;
  min-height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
}
@media (max-width: 767px) {
  .cover__img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: 560px) {
  .cover__img--zoom {
    background-position: 50% 90%;
    background-size: cover;
  }
}

.cover__quote {
  bottom: 30px;
  right: -30px;
  position: absolute;
  background-color: rgba(66, 69, 200, 0.9);
  width: 600px;
  color: #fff;
  padding: 20px;
  font-size: 14px;
}
.cover__quote i {
  display: block;
  margin-top: 30px;
}
.cover--alt .cover__quote {
  right: auto;
  left: -30px;
}
@media (max-width: 1234px) {
  .cover__quote {
    width: 100%;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .cover__quote {
    font-size: 12px;
    left: 0;
    position: static;
    margin-bottom: -20px;
    max-width: 400px;
    padding: 30px;
  }
}
@media (max-width: 560px) {
  .cover__quote {
    padding: 15px 40px;
  }
  .cover__quote i {
    margin-top: 20px;
  }
}

.cover__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px;
  padding-left: 100px;
}

.cover__content.flex-box-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 30px;
  padding-left: 100px;
  padding-top: 5%;
}

.cover--alt .cover__content {
  padding-left: 30px;
  padding-right: 100px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (max-width: 1234px) {
  .cover__content {
    padding-left: 60px;
  }
  .cover--alt .cover__content {
    padding: 50px 30px 30px;
  }
}
@media (max-width: 767px) {
  .cover__content {
    padding: 50px 30px 30px;
  }

  .cover__content.flex-box-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 50px 30px 30px;
  }
}

.cover__text {
  /* max-width: 340px; */
  font-size: 18px;
}
.cover__text p,
.cover__text ul {
  margin-bottom: 30px;
}
@media (max-width: 1234px) {
  .cover__text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .cover__text {
    font-size: 16px;
    margin: 0 auto;
  }
}
@media (max-width: 560px) {
  .cover__text .btn {
    width: 100%;
  }
}

.cover__title {
  font-size: 30px;
  color: #4245c8;
  margin-bottom: 30px;
}
@media (max-width: 1234px) {
  .cover__title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .cover__title {
    font-size: 25px;
  }
  .cover__title br {
    display: none;
  }
}

.bullets-container {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 800;
  right: 30px;
}

.cover-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cover-bullets a {
  display: inline-block;
  width: 5px;
  height: 5px;
  padding: 0;
  border-radius: 50% !important;
  border: none;
  background-color: #bbb;
  margin: 6px 0;
}
.cover-bullets .active a {
  width: 10px;
  height: 10px;
  background-color: #8bc73b;
}
@media (max-width: 560px) {
  .cover-bullets {
    display: none;
  }
}

.cover-bullets--alt a {
  opacity: 0.4;
  background-color: #fff !important;
}
.cover-bullets--alt .active a {
  opacity: 1;
}

/*------------------------------------*\
	@COMPONENTS #LIST-LINKED
\*------------------------------------*/
.list-linked {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-align: left;
}
.list-linked li {
  position: relative;
  padding-left: 40px;
  min-height: 30px;
  background: transparent url("../images/icon-square-blue.png") left top no-repeat;
}
.list-linked li:not(:last-child) {
  padding-bottom: 22px;
}
.list-linked li:not(:last-child):after {
  content: "";
  width: 1px;
  height: calc(100% - 9px);
  display: block;
  background-color: #4245c8;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  position: absolute;
  top: 18px;
  left: 12px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
@media (max-width: 768px) {
  .list-linked {
    font-size: 14px;
  }
}

.list-linked--alt li {
  background: transparent url("../images/icon-square-green.png") left top no-repeat;
}
.list-linked--alt li:not(:last-child):after {
  background-color: #8bc73b;
}

/*------------------------------------*\
    @COMPONENTS #MODAL
\*------------------------------------*/
.modal {
  text-align: center;
}

.modal-md {
  max-width: 600px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 25px 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-title {
  color: #4245c8;
  font-size: 28px;
}

.modal-body {
  padding-bottom: 40px;
}

.modal-footer {
  padding: 12px 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal-footer small {
  font-size: 12px;
  color: #888;
}

/*------------------------------------*\
    @COMPONENTS #RADIOS
\*------------------------------------*/
.radio-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}
.radio-block label {
  color: #707070;
  background-color: #ddd;
  padding: 10px 40px;
}
.radio-block input[type="radio"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.radio-block input[type="radio"]:checked + label {
  background-color: #8bc73b;
  color: #fff;
  padding: 15px 50px;
}

/*------------------------------------*\
	@COMPONENTS #LINKS
\*------------------------------------*/
.back-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  color: #999;
}
.back-link .icon {
  width: 30px;
  height: 16px;
}
.back-link span {
  margin-left: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 560px) {
  .main__header .back-link span {
    display: none;
  }
}
.back-link:hover {
  color: #8bc73b;
}
.back-link:hover span {
  margin-left: 30px;
}
@media (max-width: 560px) {
  .back-link {
    font-size: 18px;
  }
}

/*------------------------------------*\
	@COMPONENTS #BLOCK
\*------------------------------------*/
.block {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
.block ul {
  list-style: none;
}
.block ul ul {
  margin-bottom: 0;
}
@media (max-width: 560px) {
  .block {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 14px;
  }
}

.block__title {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Avenir Next Bold", sans-serif;
  margin-bottom: 30px !important;
}
@media (max-width: 560px) {
  .block__title {
    font-size: 14px;
    margin-bottom: 25px !important;
  }
}

.block__list {
  margin-bottom: 30px;
  padding-left: 0;
}
.block__list:last-child {
  margin-bottom: 0;
}
.block__list > li:not(:last-child) {
  margin-bottom: 30px;
}

/*------------------------------------*\
	@TRUMPS #HELPERS
\*------------------------------------*/
.full {
  width: 100%;
}

.clean {
  margin: 0;
  padding: 0;
}

.visuallyhidden {
  position: absolute;
  left: -999em;
}

.invisible {
  visibility: hidden !important;
}

.no-transition,
.no-transition * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.amp {
  font-family: Baskerville, Palatino, "Palatino Linotype", "Book Antiqua", serif;
  font-size: 1.4em;
  font-style: italic;
  font-weight: normal;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.flat-bottom {
  padding-bottom: 0 !important;
}

.nowrap {
  white-space: nowrap !important;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*------------------------------------*\
	@TRUMPS #LAYOUT
\*------------------------------------*/
@media (max-width: 992px) {
  .row {
    margin: 0 -15px;
  }
  .row.form-group {
    margin-bottom: 1rem;
  }
  .row > * {
    padding: 0 15px;
  }
}
.spaced1x {
  margin-bottom: 10px !important;
}

.spaced2x {
  margin-bottom: 20px !important;
}

.spaced3x {
  margin-bottom: 30px !important;
}

.spaced4x {
  margin-bottom: 40px !important;
}

.spaced5x {
  margin-bottom: 50px !important;
}

.spaced6x {
  margin-bottom: 60px !important;
}

.spaced7x {
  margin-bottom: 70px !important;
}

.spaced8x {
  margin-bottom: 80px !important;
}

.spaced9x {
  margin-bottom: 90px !important;
}

.spaced10x {
  margin-bottom: 100px !important;
}

/*------------------------------------*\
	@TRUMPS #PRINT
\*------------------------------------*/
/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/3c3c5e64604209a4d63e1e4c48dd245d45fadfd9/css/main.css#L200-L234
 */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /**
   * DonÔÇÖt show links that are fragment identifiers, or use the `javascript:`
   * pseudo protocol.
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
}

.calculator-box {
  padding: 20px;
  background-color: #f5f5f5b5;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
}

/* Slider  */
.no-ui-slider {
  width: 100%;
}

.no-ui-slider .slider-labels {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  margin-left: -3px;
  width: 100%;
  text-align: left;
  font-size: 13px;
}

.no-ui-slider .slider-labels li {
  color: #d3d3d3;
  display: inline;
  float: left;
  cursor: pointer;
}

.no-ui-slider .slider-labels li:nth-last-child(1) {
  position: absolute;
  margin-left: -3px;
  width: 25px;
}

.no-ui-slider .slider-labels li:hover {
  color: #333333;
}

.no-ui-slider .slider-labels li.active {
  color: #333333;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
  cursor: move;
}
.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}
.noUi-stacking + .noUi-origin {
  /* Fix stacking order in IE7, which incorrectly
   creates a new context for the origins. */
  *z-index: -1;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  -o-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 10px !important;
}
.noUi-horizontal .noUi-handle {
  width: 20px !important  ;
  height: 20px !important ;
  left: -13px;
  top: -7px;
  border-radius: 100%;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -6px;
  border-radius: 100%;
}
.noUi-vertical.noUi-extended {
  padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}

/* Styling;
 */
.noUi-background {
  background: #fafafa;
  -webkit-box-shadow: inset 0 1px 1px #f0f0f0;
  box-shadow: inset 0 1px 1px #f0f0f0;
}
.noUi-connect {
  background: #8bc73b !important;
  -webkit-box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  -o-transition: background 450ms;
  transition: background 450ms;
}
.noUi-origin {
  border-radius: 10px;
}
.noUi-target {
  border-radius: 10px;
  border: 1px solid #d3d3d3;
}
.noUi-target.noUi-connect {
  -webkit-box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb;
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  background: #fff;
  cursor: default;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  display: block;
  position: absolute;
  height: 14px;
  background: none !important;
  width: 1px;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid lightgray;
  outline: 0;
  font-size: 16px;
  color: #4943c1;
  font-weight: bold;
  padding: 0px !important;
  background: transparent;
  -webkit-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

.form__field::-webkit-input-placeholder {
  color: transparent;
}

.form__field::-moz-placeholder {
  color: transparent;
}

.form__field:-ms-input-placeholder {
  color: transparent;
}

.form__field::-ms-input-placeholder {
  color: transparent;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
  cursor: text;
  top: 20px;
}

label,
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  font-size: 12px;
  color: #9b9b9b;
}

.form__field:focus ~ .form__label {
  color: #009788;
}

.form__field:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid #009788;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.flex-column {
  text-align: center;
}

/* Checkbox */

.React__checkbox--input {
  display: none;
}

.React__checkbox--input + span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  background: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  margin: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}
.React__checkbox--input:checked + span {
  background: #8bc73b;
}
.React__checkbox--input:checked + span::before {
  content: "\2713";
  font-family: "Material Icons";
  font-size: 40px;
  color: white;
}
.React__checkbox--input:disabled + span {
  background: rgba(0, 0, 0, 0.35);
}
.checkboxLabel {
  top: inherit;
  position: relative;
}
.calculator_Title,
.calculator_subtitles {
  color: #4943c1;
  font-weight: bold;
}
.calculator_Title {
  font-size: 30px;
}
.calculator_subtitles {
  font-size: 16px;
}

p.italic {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
  color: #4943c1;
}

.push {
  margin-left: auto;
}

/* DropDown */

.dropdown {
  position: relative;
}
.dropdown.open > .dropdown-menu {
  display: block;
}
.dropdown .dropdown-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: #000;
  background: none;
}
.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  text-align: left;
  list-style: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dropdown .dropdown-menu li {
  cursor: pointer;
}
.dropdown .dropdown-menu li :hover {
  background: #ddd;
}
.dropdown .dropdown-menu li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.slider.slider--filter {
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
}

.flexbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flexbox-container > aside {
  padding: 10px;
  width: 50%;
  text-align: left;
}

.flexbox-container > aside.flexbox-label {
  width: 60%;
}

.flexbox-container > aside.flexbox-input {
  width: 40%;
}

.flexbox-container > aside.flex-checkbox {
  width: 15%;
}
.flexbox-container > aside.flex-checkbox-label {
  width: 85%;
}

.flexbox-container > aside.flexbox-label-checkbox {
  width: 75%;
}

.flexbox-container > aside.flexbox-input-checkbox {
  width: 25%;
}

.flexbox-container.flexbox-center {
  text-align: center !important;
}

.flexbox-container.flexbox-center > aside {
  text-align: center !important;
}
.flexbox-calculator {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  padding: 5px;
}
.padding-0px {
  padding: 0px;
}

@media screen and (max-width: 1199px) {
  .calculator-box {
    padding: 0;
  }
}
@media screen and (max-width: 1000px) {
  .calculator_subtitles {
    font-size: 14px;
  }
  .flexbox-container > aside.flex-checkbox-label {
    width: 75%;
  }

  .flexbox-container > aside.flex-checkbox {
    width: 25%;
  }
}

@media screen and (max-width: 900px) {
  .flexbox-container.flexbox-center {
    display: block;
  }
  .flexbox-container.flexbox-center > aside {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .flexbox-container.flexbox-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .flexbox-container.flexbox-center > aside {
    width: 50%;
  }
  .form__field {
    font-size: 14px;
  }
  .calculator_Title {
    font-size: 25px;
  }
}

@media screen and (max-width: 500px) {
  .flexbox-container.flexbox-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .flexbox-container.flexbox-center > aside {
    width: 50%;
  }
}
.flexbox-container > aside.flexbox-label.full-width {
  width: 100%;
}

.full-width .form__field {
  width: auto;
  max-width: 30%;
}

.calculator_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px !important;
}

.icon_phone_info {
  width: 11px;
  height: 20px;
  margin-right: 5px;
}
.header_info-hide-sm {
  color: white;
  font-size: 14px;
  font-weight: normal;
}
.margin-bottom-10 {
  margin-bottom: 5px !important;
}
