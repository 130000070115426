/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css */
input {
  margin: 0;
  font: inherit;
  color: inherit;
}

input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
}

p {
  margin: 0 0 10px;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}

input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -70%);
}

.modal.test .modal-dialog-video {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(3, 20);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(20, 0);
}

.modal-dialog-video {
  max-width: 800px;
}

.modal-dialog-video {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
}

.modal-dialog-video {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  top: 70%;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-content-video {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.modal-footer:after,
.modal-footer:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.modal-footer:after,
.row:after {
  clear: both;
}

/*! CSS Used from: https://app.lendwise.com/styles/styles.css */
body * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-moz-selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

h3 {
  line-height: 1;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: 400;
}

h3:first-child {
  margin-top: 0;
}

h3 {
  font-size: 20px;
  font-family: "Avenir Next Demi", sans-serif;
  margin-bottom: 15px;
}

p,
ul {
  margin-bottom: 30px;
}

ul:last-child {
  margin-bottom: 0;
}

ul {
  list-style-type: disc;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

input:focus {
  outline: none;
}

.form-control {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px !important;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media (max-width: 480px) {
  .form-control {
    height: 40px;
    line-height: 40px;
  }
}

input,
.form-control {
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-family: "Avenir Next Regular", sans-serif;
  font-size: 22px;
  padding: 0 !important;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 0;
}

@media (max-width: 768px) {

  input,
  .form-control {
    font-size: 20px;
  }
}

@media (max-width: 480px) {

  input,
  .form-control {
    font-size: 16px;
  }
}

input:hover,
input:focus,
.form-control:hover,
.form-control:focus {
  border-color: #4245c8;
}

input:invalid,
.form-control:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input::-ms-clear,
.form-control::-ms-clear {
  display: none;
}

input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder,
.form-control:-moz-placeholder {
  color: #999;
  opacity: 1;
}

input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #999;
}

label {
  font-weight: normal;
  font-family: "Avenir Next Regular", sans-serif;
}

.required {
  padding-right: 10px;
}

.required:after {
  content: " *";
  color: #e8425e;
}

.checkbox-box {
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 14px;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: normal;
}

input[type="checkbox"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type="checkbox"]+label {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
}

input[type="checkbox"]+label:before,
input[type="checkbox"]+label:after {
  position: absolute;
  top: 4px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.checkbox-box input[type="checkbox"]+label:before,
.checkbox-box input[type="checkbox"]+label:after {
  top: 0;
}

input[type="checkbox"]+label:before {
  content: "";
  width: 18px;
  height: 18px;
  display: block;
  border: 2px solid #ddd;
  left: 0;
}

input[type="checkbox"]+label:after {
  content: "\2713";
  color: #8bc73b;
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  left: 4px;
  opacity: 0;
}

input[type="checkbox"]+label:hover {
  color: #4245c8;
}

input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.modal {
  z-index: 3000;
}

.modal--md .modal-dialog {
  width: 100%;
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

/*! CSS Used from: https://app.lendwise.com/styles/custom.css */
@media (max-width: 580px) {
  input.form-control::-webkit-input-placeholder {
    font-size: 13px;
  }

  input.form-control:-ms-input-placeholder {
    font-size: 13px;
  }

  input.form-control::-ms-input-placeholder {
    font-size: 13px;
  }

  input.form-control::placeholder {
    font-size: 13px;
  }
}

.tooltip_ui {
  position: relative;
}

.tooltip_ui-trigger {
  display: inline-block;
  width: 100%;
}

.cookie-modal {
  text-align: justify;
  color: #444;
}

.cookie-modal p {
  margin-bottom: 10px;
}

.cookie-modal .checkbox-box {
  border: none;
  padding: 5px 0;
}

.cookie-modal .checkbox-box label {
  font-weight: 500;
  font-size: 16px;
  font-family: "Avenir Next Bold", sans-serif;
}

.cookie-modal .checkbox-box label:hover {
  color: #444;
}

.cookieList {
  list-style-type: none;
  padding-left: 0px;
}

.cookieList li {
  padding-bottom: 5px;
  margin: none;
}

.cookie-modal.in {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;
}

.cookie-modal .cookie-modal-content {
  background-color: #fff;
  border: 0;
  padding: 5px;
  border-bottom: 6px solid #4a4a4a;
}

.cookie-modal {
  padding: 0;
}

.cookie-modal-body {
  padding: 3% 5% 0% 5%;
}

.cookie-modal-footer {
  padding: 0px 10px 5px 10px;
  border: none;
}

.cookie-modal h3,
.cookie-modal p,
.cookie-modal li,
.cookie-modal li label {
  font-size: 16px !important;
}

@media (max-height: 768px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 13px !important;
  }
}

@media (max-height: 480px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 11px !important;
  }
}

@media (max-height: 667px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 12px !important;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Avenir Next Regular";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot");
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot");
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Bold";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.eot");
  src: local("Avenir Next LT Pro Bold"), local("AvenirNextLTPro-Bold"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.cookie-preferences {
  overflow-x: hidden;
  overflow-y: auto;
}