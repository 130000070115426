/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #4943c1;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #322e8a;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 5px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 10px;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}
.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0;
}
a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1260px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-flex: 0 0 8.3333333333%;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-flex: 0 0 41.6666666667%;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex: 0 0 58.3333333333%;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-flex: 0 0 66.6666666667%;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex: 0 0 83.3333333333%;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-flex: 0 0 91.6666666667%;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  -webkit-order: 1;
          order: 1;
}

.order-2 {
  -webkit-order: 2;
          order: 2;
}

.order-3 {
  -webkit-order: 3;
          order: 3;
}

.order-4 {
  -webkit-order: 4;
          order: 4;
}

.order-5 {
  -webkit-order: 5;
          order: 5;
}

.order-6 {
  -webkit-order: 6;
          order: 6;
}

.order-7 {
  -webkit-order: 7;
          order: 7;
}

.order-8 {
  -webkit-order: 8;
          order: 8;
}

.order-9 {
  -webkit-order: 9;
          order: 9;
}

.order-10 {
  -webkit-order: 10;
          order: 10;
}

.order-11 {
  -webkit-order: 11;
          order: 11;
}

.order-12 {
  -webkit-order: 12;
          order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-1 {
    -webkit-order: 1;
            order: 1;
  }

  .order-sm-2 {
    -webkit-order: 2;
            order: 2;
  }

  .order-sm-3 {
    -webkit-order: 3;
            order: 3;
  }

  .order-sm-4 {
    -webkit-order: 4;
            order: 4;
  }

  .order-sm-5 {
    -webkit-order: 5;
            order: 5;
  }

  .order-sm-6 {
    -webkit-order: 6;
            order: 6;
  }

  .order-sm-7 {
    -webkit-order: 7;
            order: 7;
  }

  .order-sm-8 {
    -webkit-order: 8;
            order: 8;
  }

  .order-sm-9 {
    -webkit-order: 9;
            order: 9;
  }

  .order-sm-10 {
    -webkit-order: 10;
            order: 10;
  }

  .order-sm-11 {
    -webkit-order: 11;
            order: 11;
  }

  .order-sm-12 {
    -webkit-order: 12;
            order: 12;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-1 {
    -webkit-order: 1;
            order: 1;
  }

  .order-md-2 {
    -webkit-order: 2;
            order: 2;
  }

  .order-md-3 {
    -webkit-order: 3;
            order: 3;
  }

  .order-md-4 {
    -webkit-order: 4;
            order: 4;
  }

  .order-md-5 {
    -webkit-order: 5;
            order: 5;
  }

  .order-md-6 {
    -webkit-order: 6;
            order: 6;
  }

  .order-md-7 {
    -webkit-order: 7;
            order: 7;
  }

  .order-md-8 {
    -webkit-order: 8;
            order: 8;
  }

  .order-md-9 {
    -webkit-order: 9;
            order: 9;
  }

  .order-md-10 {
    -webkit-order: 10;
            order: 10;
  }

  .order-md-11 {
    -webkit-order: 11;
            order: 11;
  }

  .order-md-12 {
    -webkit-order: 12;
            order: 12;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-1 {
    -webkit-order: 1;
            order: 1;
  }

  .order-lg-2 {
    -webkit-order: 2;
            order: 2;
  }

  .order-lg-3 {
    -webkit-order: 3;
            order: 3;
  }

  .order-lg-4 {
    -webkit-order: 4;
            order: 4;
  }

  .order-lg-5 {
    -webkit-order: 5;
            order: 5;
  }

  .order-lg-6 {
    -webkit-order: 6;
            order: 6;
  }

  .order-lg-7 {
    -webkit-order: 7;
            order: 7;
  }

  .order-lg-8 {
    -webkit-order: 8;
            order: 8;
  }

  .order-lg-9 {
    -webkit-order: 9;
            order: 9;
  }

  .order-lg-10 {
    -webkit-order: 10;
            order: 10;
  }

  .order-lg-11 {
    -webkit-order: 11;
            order: 11;
  }

  .order-lg-12 {
    -webkit-order: 12;
            order: 12;
  }
}
@media (min-width: 1260px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    -webkit-flex: 0 0 8.3333333333%;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    -webkit-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    -webkit-flex: 0 0 41.6666666667%;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-flex: 0 0 58.3333333333%;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    -webkit-flex: 0 0 66.6666666667%;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-flex: 0 0 83.3333333333%;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    -webkit-flex: 0 0 91.6666666667%;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-1 {
    -webkit-order: 1;
            order: 1;
  }

  .order-xl-2 {
    -webkit-order: 2;
            order: 2;
  }

  .order-xl-3 {
    -webkit-order: 3;
            order: 3;
  }

  .order-xl-4 {
    -webkit-order: 4;
            order: 4;
  }

  .order-xl-5 {
    -webkit-order: 5;
            order: 5;
  }

  .order-xl-6 {
    -webkit-order: 6;
            order: 6;
  }

  .order-xl-7 {
    -webkit-order: 7;
            order: 7;
  }

  .order-xl-8 {
    -webkit-order: 8;
            order: 8;
  }

  .order-xl-9 {
    -webkit-order: 9;
            order: 9;
  }

  .order-xl-10 {
    -webkit-order: 10;
            order: 10;
  }

  .order-xl-11 {
    -webkit-order: 11;
            order: 11;
  }

  .order-xl-12 {
    -webkit-order: 12;
            order: 12;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}
.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e9ecef;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e9ecef;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cccaee;
}

.table-hover .table-primary:hover {
  background-color: #b9b7e8;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b9b7e8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e4efcf;
}

.table-hover .table-secondary:hover {
  background-color: #d9e9bc;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d9e9bc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e4efcf;
}

.table-hover .table-success:hover {
  background-color: #d9e9bc;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d9e9bc;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b9f2fc;
}

.table-hover .table-info:hover {
  background-color: #a1edfb;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a1edfb;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fceacd;
}

.table-hover .table-warning:hover {
  background-color: #fbe0b5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe0b5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3c6d0;
}

.table-hover .table-danger:hover {
  background-color: #efb1bf;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efb1bf;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.thead-inverse th {
  color: #fff;
  background-color: #212529;
}

.thead-default th {
  color: #495057;
  background-color: #e9ecef;
}

.table-inverse {
  color: #fff;
  background-color: #212529;
}
.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #32383e;
}
.table-inverse.table-bordered {
  border: 0;
}
.table-inverse.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-inverse.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 1259px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive.table-bordered {
    border: 0;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #eee;
  background-image: none;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #eee;
  border-color: #a6a3e1;
  outline: none;
}
.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.45rem + 0);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #eee;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.6rem - 0 * 2);
  padding-bottom: calc(0.6rem - 0 * 2);
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: calc(1rem - 0 * 2);
  padding-bottom: calc(1rem - 0 * 2);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem - 0 * 2);
  padding-bottom: calc(0.25rem - 0 * 2);
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 0);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(3.3125rem + 0);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}
.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}
.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}
.form-check-inline .form-check-label {
  vertical-align: middle;
}
.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #D33257;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(211, 50, 87, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #9dc653;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 198, 83, 0.25);
}
.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip,
.was-validated .custom-select:valid ~ .invalid-feedback,
.was-validated .custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #9dc653;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(157, 198, 83, 0.25);
}
.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #9dc653;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #9dc653;
}
.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 198, 83, 0.25);
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #D33257;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 50, 87, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #D33257;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(211, 50, 87, 0.25);
}
.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #D33257;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #D33257;
}
.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 50, 87, 0.25);
}

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  border: 0 solid transparent;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: all 0.15s ease-in-out;
}
.btn:focus, .btn:hover {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(73, 67, 193, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: .65;
}
.btn:active, .btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4943c1;
  border-color: #4943c1;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3c37a7;
  border-color: #39349d;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(73, 67, 193, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #4943c1;
  border-color: #4943c1;
}
.btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  background-color: #3c37a7;
  background-image: none;
  border-color: #39349d;
}

.btn-secondary {
  color: #111;
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-secondary:hover {
  color: #111;
  background-color: #8bb63c;
  border-color: #84ad39;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(157, 198, 83, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  background-color: #8bb63c;
  background-image: none;
  border-color: #84ad39;
}

.btn-success {
  color: #111;
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-success:hover {
  color: #111;
  background-color: #8bb63c;
  border-color: #84ad39;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 3px rgba(157, 198, 83, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  background-color: #8bb63c;
  background-image: none;
  border-color: #84ad39;
}

.btn-info {
  color: #111;
  background-color: #06d1f5;
  border-color: #06d1f5;
}
.btn-info:hover {
  color: #111;
  background-color: #05b1d0;
  border-color: #05a7c3;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 3px rgba(6, 209, 245, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #06d1f5;
  border-color: #06d1f5;
}
.btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  background-color: #05b1d0;
  background-image: none;
  border-color: #05a7c3;
}

.btn-warning {
  color: #111;
  background-color: #f6b54e;
  border-color: #f6b54e;
}
.btn-warning:hover {
  color: #111;
  background-color: #f4a62a;
  border-color: #f4a11d;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(246, 181, 78, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #f6b54e;
  border-color: #f6b54e;
}
.btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  background-color: #f4a62a;
  background-image: none;
  border-color: #f4a11d;
}

.btn-danger {
  color: #fff;
  background-color: #D33257;
  border-color: #D33257;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b72748;
  border-color: #ad2544;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(211, 50, 87, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #D33257;
  border-color: #D33257;
}
.btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  background-color: #b72748;
  background-image: none;
  border-color: #ad2544;
}

.btn-light {
  color: #111;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:hover {
  color: #111;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  background-color: #fff;
  border-color: #fff;
}
.btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  background-color: #ececec;
  background-image: none;
  border-color: #e6e6e6;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  background-color: #23272b;
  background-image: none;
  border-color: #1d2124;
}

.btn-outline-primary {
  color: #4943c1;
  background-color: transparent;
  background-image: none;
  border-color: #4943c1;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4943c1;
  border-color: #4943c1;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 3px rgba(73, 67, 193, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4943c1;
  background-color: transparent;
}
.btn-outline-primary:active, .btn-outline-primary.active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4943c1;
  border-color: #4943c1;
}

.btn-outline-secondary {
  color: #9dc653;
  background-color: transparent;
  background-image: none;
  border-color: #9dc653;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px rgba(157, 198, 83, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #9dc653;
  background-color: transparent;
}
.btn-outline-secondary:active, .btn-outline-secondary.active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #9dc653;
  border-color: #9dc653;
}

.btn-outline-success {
  color: #9dc653;
  background-color: transparent;
  background-image: none;
  border-color: #9dc653;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #9dc653;
  border-color: #9dc653;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 3px rgba(157, 198, 83, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #9dc653;
  background-color: transparent;
}
.btn-outline-success:active, .btn-outline-success.active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #9dc653;
  border-color: #9dc653;
}

.btn-outline-info {
  color: #06d1f5;
  background-color: transparent;
  background-image: none;
  border-color: #06d1f5;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #06d1f5;
  border-color: #06d1f5;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 3px rgba(6, 209, 245, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #06d1f5;
  background-color: transparent;
}
.btn-outline-info:active, .btn-outline-info.active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #06d1f5;
  border-color: #06d1f5;
}

.btn-outline-warning {
  color: #f6b54e;
  background-color: transparent;
  background-image: none;
  border-color: #f6b54e;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f6b54e;
  border-color: #f6b54e;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 3px rgba(246, 181, 78, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f6b54e;
  background-color: transparent;
}
.btn-outline-warning:active, .btn-outline-warning.active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f6b54e;
  border-color: #f6b54e;
}

.btn-outline-danger {
  color: #D33257;
  background-color: transparent;
  background-image: none;
  border-color: #D33257;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #D33257;
  border-color: #D33257;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 3px rgba(211, 50, 87, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #D33257;
  background-color: transparent;
}
.btn-outline-danger:active, .btn-outline-danger.active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #D33257;
  border-color: #D33257;
}

.btn-outline-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}
.btn-outline-light:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-light:active, .btn-outline-light.active, .show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:active, .btn-outline-dark.active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-link {
  font-weight: normal;
  color: #4943c1;
  border-radius: 0;
}
.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: transparent;
}
.btn-link, .btn-link:focus, .btn-link:active {
  border-color: transparent;
  box-shadow: none;
}
.btn-link:hover {
  border-color: transparent;
}
.btn-link:focus, .btn-link:hover {
  color: #322e8a;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link:disabled {
  color: #868e96;
}
.btn-link:disabled:focus, .btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}
.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-divider {
  height: 0;
  margin: 5px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #dee2e6;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #4943c1;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.show > a {
  outline: 0;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  margin-bottom: 0;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: 0;
}

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: 0;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.6rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}
.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: 0;
}
.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: 0;
}
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: 0;
}
.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #4943c1;
}
.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #4943c1;
}
.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #cbc9ed;
}
.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}
.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #4943c1;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}
.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.45rem + 0);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #a6a3e1;
  outline: none;
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #eee;
}
.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 0);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}
.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 0 0;
}
.custom-file-control:lang(en)::before {
  content: "Browse";
}

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active, .show > .nav-pills .nav-link {
  color: #fff;
  background-color: #4943c1;
}

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px 10px;
}
.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 10px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1259px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1260px) {
  .navbar-expand-xl {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-flex-direction: row;
          flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-flex !important;
  display: flex !important;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: white;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: white;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 2rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 2rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}

.card-footer {
  padding: 1.5rem 2rem;
  background-color: #fafafa;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1.5rem;
  margin-left: -1rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 0;
            flex: 1 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
  }
}
@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group .card {
    -webkit-flex: 1 0;
            flex: 1 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            grid-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}
.breadcrumb::after {
  display: block;
  clear: both;
  content: "";
}

.breadcrumb-item {
  float: left;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #868e96;
}

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #4943c1;
  border-color: #4943c1;
}
.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4943c1;
  background-color: #fff;
  border: 1px solid #ddd;
}
.page-link:focus, .page-link:hover {
  color: #322e8a;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 90%;
  font-weight: 300;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #4943c1;
}
.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #39349d;
}

.badge-secondary {
  color: #111;
  background-color: #9dc653;
}
.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #84ad39;
}

.badge-success {
  color: #111;
  background-color: #9dc653;
}
.badge-success[href]:focus, .badge-success[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #84ad39;
}

.badge-info {
  color: #111;
  background-color: #06d1f5;
}
.badge-info[href]:focus, .badge-info[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #05a7c3;
}

.badge-warning {
  color: #111;
  background-color: #f6b54e;
}
.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #f4a11d;
}

.badge-danger {
  color: #fff;
  background-color: #D33257;
}
.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ad2544;
}

.badge-light {
  color: #111;
  background-color: #fff;
}
.badge-light[href]:focus, .badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #e6e6e6;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #262364;
  background-color: #dbd9f3;
  border-color: #cccaee;
}
.alert-primary hr {
  border-top-color: #b9b7e8;
}
.alert-primary .alert-link {
  color: #18163e;
}

.alert-secondary {
  color: #52672b;
  background-color: #ebf4dd;
  border-color: #e4efcf;
}
.alert-secondary hr {
  border-top-color: #d9e9bc;
}
.alert-secondary .alert-link {
  color: #35431c;
}

.alert-success {
  color: #52672b;
  background-color: #ebf4dd;
  border-color: #e4efcf;
}
.alert-success hr {
  border-top-color: #d9e9bc;
}
.alert-success .alert-link {
  color: #35431c;
}

.alert-info {
  color: #036d7f;
  background-color: #cdf6fd;
  border-color: #b9f2fc;
}
.alert-info hr {
  border-top-color: #a1edfb;
}
.alert-info .alert-link {
  color: #02424d;
}

.alert-warning {
  color: #805e29;
  background-color: #fdf0dc;
  border-color: #fceacd;
}
.alert-warning hr {
  border-top-color: #fbe0b5;
}
.alert-warning .alert-link {
  color: #59421d;
}

.alert-danger {
  color: #6e1a2d;
  background-color: #f6d6dd;
  border-color: #f3c6d0;
}
.alert-danger hr {
  border-top-color: #efb1bf;
}
.alert-danger .alert-link {
  color: #45101c;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-light hr {
  border-top-color: #f2f2f2;
}
.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #4943c1;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4943c1;
  border-color: #4943c1;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #262364;
  background-color: #cccaee;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #262364;
}
a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #262364;
  background-color: #b9b7e8;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #262364;
  border-color: #262364;
}

.list-group-item-secondary {
  color: #52672b;
  background-color: #e4efcf;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #52672b;
}
a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #52672b;
  background-color: #d9e9bc;
}
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #52672b;
  border-color: #52672b;
}

.list-group-item-success {
  color: #52672b;
  background-color: #e4efcf;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #52672b;
}
a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #52672b;
  background-color: #d9e9bc;
}
a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #52672b;
  border-color: #52672b;
}

.list-group-item-info {
  color: #036d7f;
  background-color: #b9f2fc;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #036d7f;
}
a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #036d7f;
  background-color: #a1edfb;
}
a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #036d7f;
  border-color: #036d7f;
}

.list-group-item-warning {
  color: #805e29;
  background-color: #fceacd;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #805e29;
}
a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #805e29;
  background-color: #fbe0b5;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #805e29;
  border-color: #805e29;
}

.list-group-item-danger {
  color: #6e1a2d;
  background-color: #f3c6d0;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #6e1a2d;
}
a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #6e1a2d;
  background-color: #efb1bf;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #6e1a2d;
  border-color: #6e1a2d;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #858585;
}
a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #858585;
  background-color: #f2f2f2;
}
a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}
a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.6;
}

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 30px;
}

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 30px;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 80px auto;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}
.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}
.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #fff;
}
.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px;
}
.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
}
.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}
.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}
.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}
.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px;
}
.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
}
.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
}
.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 340px;
  padding: 3px 8px;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow::before {
  content: "";
  border-width: 11px;
}
.popover .arrow::after {
  content: "";
  border-width: 11px;
}
.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 10px;
}
.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-bottom-width: 0;
}
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: -11px;
  margin-left: -6px;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: -10px;
  margin-left: -6px;
  border-top-color: #fff;
}
.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
  margin-left: 10px;
}
.popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
  left: 0;
}
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  margin-top: -8px;
  border-left-width: 0;
}
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: -11px;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: -10px;
  border-right-color: #fff;
}
.popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 10px;
}
.popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  margin-left: -7px;
  border-top-width: 0;
}
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: -11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: -10px;
  border-bottom-color: #fff;
}
.popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
  margin-right: 10px;
}
.popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
  right: 0;
}
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  margin-top: -8px;
  border-right-width: 0;
}
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: -11px;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: -10px;
  border-left-color: #fff;
}

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 9px 14px;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4943c1 !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #39349d !important;
}

.bg-secondary {
  background-color: #9dc653 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #84ad39 !important;
}

.bg-success {
  background-color: #9dc653 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #84ad39 !important;
}

.bg-info {
  background-color: #06d1f5 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #05a7c3 !important;
}

.bg-warning {
  background-color: #f6b54e !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #f4a11d !important;
}

.bg-danger {
  background-color: #D33257 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #ad2544 !important;
}

.bg-light {
  background-color: #fff !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4943c1 !important;
}

.border-secondary {
  border-color: #9dc653 !important;
}

.border-success {
  border-color: #9dc653 !important;
}

.border-info {
  border-color: #06d1f5 !important;
}

.border-warning {
  border-color: #f6b54e !important;
}

.border-danger {
  border-color: #D33257 !important;
}

.border-light {
  border-color: #fff !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1260px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}
.d-print-block {
  display: none !important;
}
@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}
@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important;
}

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }

  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }

  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }

  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }

  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }

  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }

  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }

  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }

  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }

  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }

  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }

  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }

  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }

  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }

  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }

  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }

  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }

  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }

  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }

  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }

  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }

  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }

  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }

  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }

  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }

  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }

  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }

  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }

  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }

  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }

  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }

  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }

  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }

  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
@media (min-width: 1260px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }

  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }

  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }

  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }

  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }

  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }

  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }

  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }

  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }

  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }

  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1260px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 10px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.mx-1 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.my-1 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.mx-2 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.my-2 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-3 {
  margin: 30px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.ml-3 {
  margin-left: 30px !important;
}

.mx-3 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.my-3 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-4 {
  margin: 40px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.ml-4 {
  margin-left: 40px !important;
}

.mx-4 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.my-4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-5 {
  margin: 50px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mr-5 {
  margin-right: 50px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.ml-5 {
  margin-left: 50px !important;
}

.mx-5 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my-5 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 10px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.px-1 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.py-1 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.px-2 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.py-2 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-3 {
  padding: 30px !important;
}

.pt-3 {
  padding-top: 30px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pb-3 {
  padding-bottom: 30px !important;
}

.pl-3 {
  padding-left: 30px !important;
}

.px-3 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.py-3 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-4 {
  padding: 40px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pl-4 {
  padding-left: 40px !important;
}

.px-4 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py-4 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-5 {
  padding: 50px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pr-5 {
  padding-right: 50px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.px-5 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.py-5 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-sm-1 {
    margin: 10px !important;
  }

  .mt-sm-1 {
    margin-top: 10px !important;
  }

  .mr-sm-1 {
    margin-right: 10px !important;
  }

  .mb-sm-1 {
    margin-bottom: 10px !important;
  }

  .ml-sm-1 {
    margin-left: 10px !important;
  }

  .mx-sm-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .my-sm-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-sm-2 {
    margin: 20px !important;
  }

  .mt-sm-2 {
    margin-top: 20px !important;
  }

  .mr-sm-2 {
    margin-right: 20px !important;
  }

  .mb-sm-2 {
    margin-bottom: 20px !important;
  }

  .ml-sm-2 {
    margin-left: 20px !important;
  }

  .mx-sm-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .my-sm-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-sm-3 {
    margin: 30px !important;
  }

  .mt-sm-3 {
    margin-top: 30px !important;
  }

  .mr-sm-3 {
    margin-right: 30px !important;
  }

  .mb-sm-3 {
    margin-bottom: 30px !important;
  }

  .ml-sm-3 {
    margin-left: 30px !important;
  }

  .mx-sm-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .my-sm-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-sm-4 {
    margin: 40px !important;
  }

  .mt-sm-4 {
    margin-top: 40px !important;
  }

  .mr-sm-4 {
    margin-right: 40px !important;
  }

  .mb-sm-4 {
    margin-bottom: 40px !important;
  }

  .ml-sm-4 {
    margin-left: 40px !important;
  }

  .mx-sm-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .my-sm-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-sm-5 {
    margin: 50px !important;
  }

  .mt-sm-5 {
    margin-top: 50px !important;
  }

  .mr-sm-5 {
    margin-right: 50px !important;
  }

  .mb-sm-5 {
    margin-bottom: 50px !important;
  }

  .ml-sm-5 {
    margin-left: 50px !important;
  }

  .mx-sm-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .my-sm-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-sm-1 {
    padding: 10px !important;
  }

  .pt-sm-1 {
    padding-top: 10px !important;
  }

  .pr-sm-1 {
    padding-right: 10px !important;
  }

  .pb-sm-1 {
    padding-bottom: 10px !important;
  }

  .pl-sm-1 {
    padding-left: 10px !important;
  }

  .px-sm-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .py-sm-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-sm-2 {
    padding: 20px !important;
  }

  .pt-sm-2 {
    padding-top: 20px !important;
  }

  .pr-sm-2 {
    padding-right: 20px !important;
  }

  .pb-sm-2 {
    padding-bottom: 20px !important;
  }

  .pl-sm-2 {
    padding-left: 20px !important;
  }

  .px-sm-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .py-sm-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-sm-3 {
    padding: 30px !important;
  }

  .pt-sm-3 {
    padding-top: 30px !important;
  }

  .pr-sm-3 {
    padding-right: 30px !important;
  }

  .pb-sm-3 {
    padding-bottom: 30px !important;
  }

  .pl-sm-3 {
    padding-left: 30px !important;
  }

  .px-sm-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .py-sm-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-sm-4 {
    padding: 40px !important;
  }

  .pt-sm-4 {
    padding-top: 40px !important;
  }

  .pr-sm-4 {
    padding-right: 40px !important;
  }

  .pb-sm-4 {
    padding-bottom: 40px !important;
  }

  .pl-sm-4 {
    padding-left: 40px !important;
  }

  .px-sm-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .py-sm-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-sm-5 {
    padding: 50px !important;
  }

  .pt-sm-5 {
    padding-top: 50px !important;
  }

  .pr-sm-5 {
    padding-right: 50px !important;
  }

  .pb-sm-5 {
    padding-bottom: 50px !important;
  }

  .pl-sm-5 {
    padding-left: 50px !important;
  }

  .px-sm-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-sm-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-md-1 {
    margin: 10px !important;
  }

  .mt-md-1 {
    margin-top: 10px !important;
  }

  .mr-md-1 {
    margin-right: 10px !important;
  }

  .mb-md-1 {
    margin-bottom: 10px !important;
  }

  .ml-md-1 {
    margin-left: 10px !important;
  }

  .mx-md-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .my-md-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-md-2 {
    margin: 20px !important;
  }

  .mt-md-2 {
    margin-top: 20px !important;
  }

  .mr-md-2 {
    margin-right: 20px !important;
  }

  .mb-md-2 {
    margin-bottom: 20px !important;
  }

  .ml-md-2 {
    margin-left: 20px !important;
  }

  .mx-md-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .my-md-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-md-3 {
    margin: 30px !important;
  }

  .mt-md-3 {
    margin-top: 30px !important;
  }

  .mr-md-3 {
    margin-right: 30px !important;
  }

  .mb-md-3 {
    margin-bottom: 30px !important;
  }

  .ml-md-3 {
    margin-left: 30px !important;
  }

  .mx-md-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .my-md-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-md-4 {
    margin: 40px !important;
  }

  .mt-md-4 {
    margin-top: 40px !important;
  }

  .mr-md-4 {
    margin-right: 40px !important;
  }

  .mb-md-4 {
    margin-bottom: 40px !important;
  }

  .ml-md-4 {
    margin-left: 40px !important;
  }

  .mx-md-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .my-md-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-md-5 {
    margin: 50px !important;
  }

  .mt-md-5 {
    margin-top: 50px !important;
  }

  .mr-md-5 {
    margin-right: 50px !important;
  }

  .mb-md-5 {
    margin-bottom: 50px !important;
  }

  .ml-md-5 {
    margin-left: 50px !important;
  }

  .mx-md-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .my-md-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0 {
    padding-left: 0 !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-md-1 {
    padding: 10px !important;
  }

  .pt-md-1 {
    padding-top: 10px !important;
  }

  .pr-md-1 {
    padding-right: 10px !important;
  }

  .pb-md-1 {
    padding-bottom: 10px !important;
  }

  .pl-md-1 {
    padding-left: 10px !important;
  }

  .px-md-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .py-md-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-md-2 {
    padding: 20px !important;
  }

  .pt-md-2 {
    padding-top: 20px !important;
  }

  .pr-md-2 {
    padding-right: 20px !important;
  }

  .pb-md-2 {
    padding-bottom: 20px !important;
  }

  .pl-md-2 {
    padding-left: 20px !important;
  }

  .px-md-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .py-md-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-md-3 {
    padding: 30px !important;
  }

  .pt-md-3 {
    padding-top: 30px !important;
  }

  .pr-md-3 {
    padding-right: 30px !important;
  }

  .pb-md-3 {
    padding-bottom: 30px !important;
  }

  .pl-md-3 {
    padding-left: 30px !important;
  }

  .px-md-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .py-md-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-md-4 {
    padding: 40px !important;
  }

  .pt-md-4 {
    padding-top: 40px !important;
  }

  .pr-md-4 {
    padding-right: 40px !important;
  }

  .pb-md-4 {
    padding-bottom: 40px !important;
  }

  .pl-md-4 {
    padding-left: 40px !important;
  }

  .px-md-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .py-md-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-md-5 {
    padding: 50px !important;
  }

  .pt-md-5 {
    padding-top: 50px !important;
  }

  .pr-md-5 {
    padding-right: 50px !important;
  }

  .pb-md-5 {
    padding-bottom: 50px !important;
  }

  .pl-md-5 {
    padding-left: 50px !important;
  }

  .px-md-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-md-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-lg-1 {
    margin: 10px !important;
  }

  .mt-lg-1 {
    margin-top: 10px !important;
  }

  .mr-lg-1 {
    margin-right: 10px !important;
  }

  .mb-lg-1 {
    margin-bottom: 10px !important;
  }

  .ml-lg-1 {
    margin-left: 10px !important;
  }

  .mx-lg-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .my-lg-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-lg-2 {
    margin: 20px !important;
  }

  .mt-lg-2 {
    margin-top: 20px !important;
  }

  .mr-lg-2 {
    margin-right: 20px !important;
  }

  .mb-lg-2 {
    margin-bottom: 20px !important;
  }

  .ml-lg-2 {
    margin-left: 20px !important;
  }

  .mx-lg-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .my-lg-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-lg-3 {
    margin: 30px !important;
  }

  .mt-lg-3 {
    margin-top: 30px !important;
  }

  .mr-lg-3 {
    margin-right: 30px !important;
  }

  .mb-lg-3 {
    margin-bottom: 30px !important;
  }

  .ml-lg-3 {
    margin-left: 30px !important;
  }

  .mx-lg-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .my-lg-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-lg-4 {
    margin: 40px !important;
  }

  .mt-lg-4 {
    margin-top: 40px !important;
  }

  .mr-lg-4 {
    margin-right: 40px !important;
  }

  .mb-lg-4 {
    margin-bottom: 40px !important;
  }

  .ml-lg-4 {
    margin-left: 40px !important;
  }

  .mx-lg-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .my-lg-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-lg-5 {
    margin: 50px !important;
  }

  .mt-lg-5 {
    margin-top: 50px !important;
  }

  .mr-lg-5 {
    margin-right: 50px !important;
  }

  .mb-lg-5 {
    margin-bottom: 50px !important;
  }

  .ml-lg-5 {
    margin-left: 50px !important;
  }

  .mx-lg-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .my-lg-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-lg-1 {
    padding: 10px !important;
  }

  .pt-lg-1 {
    padding-top: 10px !important;
  }

  .pr-lg-1 {
    padding-right: 10px !important;
  }

  .pb-lg-1 {
    padding-bottom: 10px !important;
  }

  .pl-lg-1 {
    padding-left: 10px !important;
  }

  .px-lg-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .py-lg-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-lg-2 {
    padding: 20px !important;
  }

  .pt-lg-2 {
    padding-top: 20px !important;
  }

  .pr-lg-2 {
    padding-right: 20px !important;
  }

  .pb-lg-2 {
    padding-bottom: 20px !important;
  }

  .pl-lg-2 {
    padding-left: 20px !important;
  }

  .px-lg-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .py-lg-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-lg-3 {
    padding: 30px !important;
  }

  .pt-lg-3 {
    padding-top: 30px !important;
  }

  .pr-lg-3 {
    padding-right: 30px !important;
  }

  .pb-lg-3 {
    padding-bottom: 30px !important;
  }

  .pl-lg-3 {
    padding-left: 30px !important;
  }

  .px-lg-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .py-lg-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-lg-4 {
    padding: 40px !important;
  }

  .pt-lg-4 {
    padding-top: 40px !important;
  }

  .pr-lg-4 {
    padding-right: 40px !important;
  }

  .pb-lg-4 {
    padding-bottom: 40px !important;
  }

  .pl-lg-4 {
    padding-left: 40px !important;
  }

  .px-lg-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .py-lg-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-lg-5 {
    padding: 50px !important;
  }

  .pt-lg-5 {
    padding-top: 50px !important;
  }

  .pr-lg-5 {
    padding-right: 50px !important;
  }

  .pb-lg-5 {
    padding-bottom: 50px !important;
  }

  .pl-lg-5 {
    padding-left: 50px !important;
  }

  .px-lg-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-lg-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (min-width: 1260px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .m-xl-1 {
    margin: 10px !important;
  }

  .mt-xl-1 {
    margin-top: 10px !important;
  }

  .mr-xl-1 {
    margin-right: 10px !important;
  }

  .mb-xl-1 {
    margin-bottom: 10px !important;
  }

  .ml-xl-1 {
    margin-left: 10px !important;
  }

  .mx-xl-1 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .my-xl-1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .m-xl-2 {
    margin: 20px !important;
  }

  .mt-xl-2 {
    margin-top: 20px !important;
  }

  .mr-xl-2 {
    margin-right: 20px !important;
  }

  .mb-xl-2 {
    margin-bottom: 20px !important;
  }

  .ml-xl-2 {
    margin-left: 20px !important;
  }

  .mx-xl-2 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .my-xl-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-xl-3 {
    margin: 30px !important;
  }

  .mt-xl-3 {
    margin-top: 30px !important;
  }

  .mr-xl-3 {
    margin-right: 30px !important;
  }

  .mb-xl-3 {
    margin-bottom: 30px !important;
  }

  .ml-xl-3 {
    margin-left: 30px !important;
  }

  .mx-xl-3 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }

  .my-xl-3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .m-xl-4 {
    margin: 40px !important;
  }

  .mt-xl-4 {
    margin-top: 40px !important;
  }

  .mr-xl-4 {
    margin-right: 40px !important;
  }

  .mb-xl-4 {
    margin-bottom: 40px !important;
  }

  .ml-xl-4 {
    margin-left: 40px !important;
  }

  .mx-xl-4 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .my-xl-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-xl-5 {
    margin: 50px !important;
  }

  .mt-xl-5 {
    margin-top: 50px !important;
  }

  .mr-xl-5 {
    margin-right: 50px !important;
  }

  .mb-xl-5 {
    margin-bottom: 50px !important;
  }

  .ml-xl-5 {
    margin-left: 50px !important;
  }

  .mx-xl-5 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }

  .my-xl-5 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .p-xl-1 {
    padding: 10px !important;
  }

  .pt-xl-1 {
    padding-top: 10px !important;
  }

  .pr-xl-1 {
    padding-right: 10px !important;
  }

  .pb-xl-1 {
    padding-bottom: 10px !important;
  }

  .pl-xl-1 {
    padding-left: 10px !important;
  }

  .px-xl-1 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .py-xl-1 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .p-xl-2 {
    padding: 20px !important;
  }

  .pt-xl-2 {
    padding-top: 20px !important;
  }

  .pr-xl-2 {
    padding-right: 20px !important;
  }

  .pb-xl-2 {
    padding-bottom: 20px !important;
  }

  .pl-xl-2 {
    padding-left: 20px !important;
  }

  .px-xl-2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .py-xl-2 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .p-xl-3 {
    padding: 30px !important;
  }

  .pt-xl-3 {
    padding-top: 30px !important;
  }

  .pr-xl-3 {
    padding-right: 30px !important;
  }

  .pb-xl-3 {
    padding-bottom: 30px !important;
  }

  .pl-xl-3 {
    padding-left: 30px !important;
  }

  .px-xl-3 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  .py-xl-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .p-xl-4 {
    padding: 40px !important;
  }

  .pt-xl-4 {
    padding-top: 40px !important;
  }

  .pr-xl-4 {
    padding-right: 40px !important;
  }

  .pb-xl-4 {
    padding-bottom: 40px !important;
  }

  .pl-xl-4 {
    padding-left: 40px !important;
  }

  .px-xl-4 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .py-xl-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-xl-5 {
    padding: 50px !important;
  }

  .pt-xl-5 {
    padding-top: 50px !important;
  }

  .pr-xl-5 {
    padding-right: 50px !important;
  }

  .pb-xl-5 {
    padding-bottom: 50px !important;
  }

  .pl-xl-5 {
    padding-left: 50px !important;
  }

  .px-xl-5 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }

  .py-xl-5 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1260px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4943c1 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #39349d !important;
}

.text-secondary {
  color: #9dc653 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #84ad39 !important;
}

.text-success {
  color: #9dc653 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #84ad39 !important;
}

.text-info {
  color: #06d1f5 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #05a7c3 !important;
}

.text-warning {
  color: #f6b54e !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #f4a11d !important;
}

.text-danger {
  color: #D33257 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #ad2544 !important;
}

.text-light {
  color: #fff !important;
}

a.text-light:focus, a.text-light:hover {
  color: #e6e6e6 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@charset "UTF-8";
/* -----------------------------------------------------
+
+ FRONT-MAN.com
+
------------------------------------------------------ */
@font-face {
  font-family: "Avenir Next Bold";
  font-display: block;
  src: url(/static/media/AvenirNextLTPro-Bold.3bd5209e.eot);
  src: local("Avenir Next LT Pro Bold"), local("AvenirNextLTPro-Bold"), url(/static/media/AvenirNextLTPro-Bold.3bd5209e.eot?#iefix) format("embedded-opentype"),
    url(/static/media/AvenirNextLTPro-Bold.030574c3.woff2) format("woff2"), url(/static/media/AvenirNextLTPro-Bold.4130f408.woff) format("woff"),
    url(/static/media/AvenirNextLTPro-Bold.49179bd0.ttf) format("truetype"), url(/static/media/AvenirNextLTPro-Bold.1247b892.svg#AvenirNextLTPro-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Demi";
  font-display: block;
  src: url(/static/media/AvenirNextLTPro-Demi.5d06b1f5.eot);
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"), url(/static/media/AvenirNextLTPro-Demi.5d06b1f5.eot?#iefix) format("embedded-opentype"),
    url(/static/media/AvenirNextLTPro-Demi.5f22ddd7.woff2) format("woff2"), url(/static/media/AvenirNextLTPro-Demi.f2a0852d.woff) format("woff"),
    url(/static/media/AvenirNextLTPro-Demi.388f47a8.ttf) format("truetype"), url(/static/media/AvenirNextLTPro-Demi.1fec9e37.svg#AvenirNextLTPro-Demi) format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Regular";
  font-display: block;
  src: url(/static/media/AvenirNextLTPro-Regular.98e4e241.eot);
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"), url(/static/media/AvenirNextLTPro-Regular.98e4e241.eot?#iefix) format("embedded-opentype"),
    url(/static/media/AvenirNextLTPro-Regular.a2a552e5.woff2) format("woff2"), url(/static/media/AvenirNextLTPro-Regular.ee81bb29.woff) format("woff"),
    url(/static/media/AvenirNextLTPro-Regular.60f09ef0.ttf) format("truetype"), url(/static/media/AvenirNextLTPro-Regular.e6d25b0e.svg#AvenirNextLTPro-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Medium";
  font-display: block;
  src: url(/static/media/AvenirNextLTPro-Medium.222376e6.eot);
  src: local("Avenir Next LT Pro Medium"), local("AvenirNextLTPro-Medium"), url(/static/media/AvenirNextLTPro-Medium.222376e6.eot?#iefix) format("embedded-opentype"),
    url(/static/media/AvenirNextLTPro-Medium.50c1bd64.woff2) format("woff2"), url(/static/media/AvenirNextLTPro-Medium.b3be037b.woff) format("woff"),
    url(/static/media/AvenirNextLTPro-Medium.ed6e7833.ttf) format("truetype"), url(/static/media/AvenirNextLTPro-Medium.1206dacf.svg#AvenirNextLTPro-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next MediumIt";
  font-display: block;
  src: url(/static/media/AvenirNextLTPro-MediumIt.97cb3f96.eot);
  src: local("Avenir Next LT Pro MediumIt"), local("AvenirNextLTPro-MediumIt"), url(/static/media/AvenirNextLTPro-MediumIt.97cb3f96.eot?#iefix) format("embedded-opentype"),
    url(/static/media/AvenirNextLTPro-MediumIt.817c55b7.woff2) format("woff2"), url(/static/media/AvenirNextLTPro-MediumIt.9c4b45ca.woff) format("woff"),
    url(/static/media/AvenirNextLTPro-MediumIt.33ea27f1.ttf) format("truetype"), url(/static/media/AvenirNextLTPro-MediumIt.bcec7d26.svg#AvenirNextLTPro-MediumIt) format("svg");
  font-weight: normal;
  font-style: italic;
}
/*------------------------------------*\
    @BASE #PAGE
\*------------------------------------*/
html {
  min-height: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Avenir Next Medium", sans-serif;
  font-size: 18px;
  line-height: 1.375;
  font-weight: 400;
  background-color: #fff;
  color: #707070;
  height: 100%;
  min-width: 320px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  -webkit-flex-direction: column;
          flex-direction: column;
}
body * {
  box-sizing: border-box;
  min-width: 0;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

/*------------------------------------*\
    @BASE #TYPE
\*------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  color: #707070;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p:last-child {
  margin-bottom: 0;
}

dl dt {
  font-weight: 400;
}
dl dd {
  margin-bottom: 0;
}

p:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 560px) {
  p:not(:last-child) {
    margin-bottom: 20px;
  }
}

hr {
  margin: 40px 0;
}

strong {
  font-weight: 700;
}

/*------------------------------------*\
    @BASE #LINKS
\*------------------------------------*/
a {
  outline: none !important;
  /* removing outline in Mozilla */
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
a:hover,
a:focus {
  text-decoration: none;
}
p a {
  text-decoration: underline;
  color: #707070;
}
p a:hover {
  color: #4245c8;
  text-decoration: underline;
}

/*------------------------------------*\
    @OBJECTS #BUTTONS
\*------------------------------------*/
button,
button:focus,
button:active {
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
button:not(.btn),
button:focus:not(.btn),
button:active:not(.btn) {
  border: none;
}

.btn {
  cursor: pointer;
  font-family: "Avenir Next Demi", sans-serif;
}

*[class*="btn-outline-"] {
  border-width: 2px;
  box-shadow: none !important;
}

/*------------------------------------*\
    @OBJECTS #ICONS
\*------------------------------------*/
.icon {
  fill: currentColor;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

/*------------------------------------*\
    @COMPONENTS #BUTTONS
\*------------------------------------*/
.btn--full {
  width: 100%;
}

@media (max-width: 560px) {
  .btn--full-xs {
    width: 100%;
  }
}

.btn--primary,
.btn--primary:focus {
  background-color: #4245c8;
  color: #fff;
}
.btn--primary:hover,
.btn--primary:focus:hover {
  background-color: #3033a7;
}

.btn--alt,
.btn--alt:focus {
  background-color: #8bc73b;
  color: #fff !important;
}
.btn--alt:hover,
.btn--alt:focus:hover {
  background-color: #70a12e;
}

.btn--arrow {
  position: relative;
  padding: 15px 80px 15px 20px;
  text-align: left;
  font-size: 20px;
}
.btn--arrow .icon {
  width: 48px;
  height: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--arrow .icon-arrow-right {
  right: 20px;
}
@media (max-width: 560px) {
  .btn--arrow .icon-arrow-right {
    right: 10px;
  }
}
.btn--arrow .icon-arrow-left {
  left: 20px;
}
@media (max-width: 560px) {
  .btn--arrow .icon-arrow-left {
    left: 10px;
  }
}
.btn--arrow:hover .icon-arrow-right {
  right: 12px;
}
@media (max-width: 560px) {
  .btn--arrow:hover .icon-arrow-right {
    right: 0;
  }
}
.btn--arrow:hover .icon-arrow-left {
  left: 12px;
}
@media (max-width: 560px) {
  .btn--arrow:hover .icon-arrow-left {
    left: 0;
  }
}
@media (max-width: 992px) {
  .btn--arrow {
    font-size: 18px;
  }
}

.btn--arrow-left {
  padding-right: 20px;
  padding-left: 160px;
  text-align: right;
}

/*------------------------------------*\
    @COMPONENTS #FORMS
\*------------------------------------*/
input,
.form-control {
  -webkit-appearance: none;
  /* removing inner shadow on iOS inputs */
  -webkit-font-smoothing: inherit;
  background-color: transparent !important;
  box-shadow: none !important;
  font-family: "Avenir Next Medium", sans-serif;
  font-size: 22px;
  padding: 15px 0 !important;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 0;
  /*PLACEHOLDER*/
}
@media (max-width: 768px) {
  input,
  .form-control {
    font-size: 20px;
  }
}
@media (max-width: 560px) {
  input,
  .form-control {
    font-size: 16px;
  }
}
input:hover,
input:focus,
.form-control:hover,
.form-control:focus {
  border-color: #4245c8;
}
input:invalid,
.form-control:invalid {
  box-shadow: none;
  /* fixes mozilla red outline (shadow) */
}
input[readonly],
.form-control[readonly] {
  cursor: text;
}
input::-ms-clear,
.form-control::-ms-clear {
  display: none;
  /* remove IE clear icon */
}
input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #999;
}
input:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
  opacity: 1;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
  opacity: 1;
}
input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control[disabled] {
  background-color: #fff;
}

.form-group--valid .form-control {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjUuMSAxOC4yIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNS4xIDE4LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojOEJDNjNGO308L3N0eWxlPjxnPjxwb2x5Z29uIGlkPSJYTUxJRF8zXyIgY2xhc3M9InN0MCIgcG9pbnRzPSI5LjEsMTguMiAwLDkuMSAyLjEsNyA5LjEsMTMuOSAyMywwIDI1LjEsMi4xICIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-size: 25px 18px;
  background-position: 100% 50%;
}
@media (max-width: 560px) {
  .form-group--valid .form-control {
    background-size: 19px 14px;
  }
}

.form-group--error {
  margin-bottom: 30px;
}
.form-group--error .form-control {
  border-color: #e8425e;
}
.form-group--error label {
  color: #e8425e !important;
}
@media (max-width: 560px) {
  .form-group--error {
    margin-bottom: 40px !important;
  }
}

.form-group {
  position: relative;
  margin-bottom: 40px;
}
.form-group::after {
  display: block;
  clear: both;
  content: "";
}
.form-group:last-child {
  margin-bottom: 0;
}

label {
  font-weight: normal;
  font-family: "Avenir Next Medium", sans-serif;
}
label a {
  text-decoration: underline;
}
label a,
label a:focus {
  color: #4245c8;
}
label a:hover,
label a:focus:hover {
  color: #8bc73b;
}

.label-flat {
  color: #aaa;
  text-align: left;
  display: block;
  cursor: default;
  margin-bottom: 20px;
  font-size: 15px;
}

.form-hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/*------------------------------------*\
    @COMPONENTS #FORMS-LABELED
\*------------------------------------*/
.form-labeled {
  position: relative;
  margin-bottom: 40px !important;
}
.form-labeled:after {
  content: "";
  width: 0;
  height: 2px;
  display: block;
  background-color: #4245c8;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}
.form-labeled label {
  font-family: "Avenir Next Medium", sans-serif;
  color: #999;
  font-weight: normal;
  font-size: 22px;
  cursor: text;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
.form-labeled label *[data-toggle="tooltip"] .icon {
  pointer-events: none;
}
.form-labeled input:hover,
.form-labeled input:focus {
  border-color: #ddd;
}
.form-labeled.focus label,
.form-labeled.form-labeled--focused label,
.form-labeled.active label {
  font-size: 14px;
  top: -15px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  cursor: default;
}
@media (max-width: 560px) {
  .form-labeled.focus label,
  .form-labeled.form-labeled--focused label,
  .form-labeled.active label {
    font-size: 12px;
    top: -10px;
  }
}
.form-labeled + .btn {
  margin-top: 10px;
}
.form-labeled.focus:after {
  width: 100%;
}
@media (max-width: 768px) {
  .form-labeled {
    margin-bottom: 30px !important;
  }
  .form-labeled label {
    font-size: 20px;
  }
}
@media (max-width: 560px) {
  .form-labeled {
    margin-bottom: 15px !important;
  }
  .form-labeled.form-group--error {
    margin-bottom: 40px !important;
  }
  .form-labeled label {
    font-size: 16px;
  }
  .form-labeled + .btn {
    margin-top: 0;
  }
}

@media (min-width: 561px) {
  .form-group--xs {
    margin-bottom: 0 !important;
  }
}

.required {
  padding-right: 10px;
}
.required:after {
  content: " *";
  color: #e8425e;
}

.hideShowPassword-wrapper {
  width: 100% !important;
}

.hideShowPassword-toggle {
  font-size: 16px;
  text-transform: uppercase;
  color: #444;
  background-color: transparent;
  font-family: "Avenir Next Demi", sans-serif;
  padding: 0;
  line-height: 1;
  height: auto;
  margin-top: 0;
}

/*------------------------------------*\
	@COMPONENTS #PAGE
\*------------------------------------*/
.menu-on {
  overflow: hidden;
}

.page {
  -webkit-flex: 1 0;
          flex: 1 0;
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 100px;
}
.page.page--home {
  padding-top: 0;
}
@media (max-width: 767px) {
  .page {
    padding-top: 90px;
  }
}
@media (max-width: 560px) {
  .page {
    padding-top: 70px;
  }
}

.main {
  position: relative;
}

.main__header,
.main__footer {
  padding: 40px 0;
  border-bottom: 1px solid #ddd;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
@media (max-width: 560px) {
  .main__header,
  .main__footer {
    padding: 25px 0;
  }
}

.main__footer {
  -webkit-justify-content: center;
          justify-content: center;
}

.main__title {
  color: #4245c8;
  font-size: 32px;
  margin: 0;
}
@media (max-width: 767px) {
  .main__title {
    font-size: 24px;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
}

/*------------------------------------*\
	@COMPONENTS #HEADER
\*------------------------------------*/
.header {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease 0s;
}
.header .container {
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
@media (max-width: 768px) {
  .header .container {
    height: 90px;
  }
}
@media (max-width: 560px) {
  .header .container {
    height: 70px;
  }
}

@media (min-width: 992px) {
  .header--top {
    background-color: transparent;
    box-shadow: none;
  }
  .header--top .header__nav {
    transition: all 0.3s ease 0s;
    opacity: 0;
  }
}

.header__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.header__nav li:not(:last-child) {
  margin-right: 50px;
}
.header__nav a:not(.btn) {
  color: #707070;
}
.header__nav a:not(.btn):hover {
  color: #8bc73b;
}
@media (max-width: 560px) {
  .header__nav {
    display: none;
  }
}

@media (max-width: 768px) {
  .header__item {
    display: none;
  }
}

/*------------------------------------*\
	@COMPONENTS #HEADER
\*------------------------------------*/
.logo-wrap {
  width: 170px;
  height: 34px;
  position: relative;
}
.logo-wrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease 0s;
}
@media (max-width: 560px) {
  .logo-wrap {
    width: 142px;
    height: 30px;
  }
}
.logo-wrap .logo--color {
  opacity: 1;
}
@media (min-width: 992px) {
  .header--top .logo-wrap .logo--white {
    opacity: 1;
  }
}

/*------------------------------------*\
	@COMPONENTS #MENU
\*------------------------------------*/
.menu-trigger {
  padding: 0;
  background-color: transparent;
  width: 36px;
  height: 36px;
  position: relative;
  color: #707070;
}
.menu-trigger .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 36px;
  height: 24px;
}
.menu-trigger:hover {
  color: #8bc73b;
}
@media (min-width: 769px) {
  .menu-trigger {
    display: none;
  }
}
@media (max-width: 560px) {
  .menu-trigger .icon {
    width: 30px;
    height: 20px;
  }
}

.menu-overlay {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;
  z-index: 1000;
  -webkit-transform: translateX(-100vw);
  transform: translateX(-100vw);
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}
.menu-on .menu-overlay {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@media (max-width: 560px) {
  .menu-overlay {
    display: none;
  }
}

.menu {
  background-color: rgba(46, 55, 73, 0.95);
  color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  color: #fff;
  padding: 30px;
  -webkit-transform: translateX(100vw);
  transform: translateX(100vw);
  transition: all 0.3s linear;
  z-index: 1001;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow-y: auto;
}
.menu-on .menu {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@media (max-width: 560px) {
  .menu {
    left: 0;
  }
}

.menu__close {
  background-color: transparent;
  padding: 0;
  color: #fff;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.menu__close .icon {
  width: 22px;
  height: 22px;
}

.menu__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 26px;
  margin: 30px 0;
}
.menu__nav li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.menu__nav li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.menu__nav a {
  display: block;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
}

/*------------------------------------*\
	@COMPONENTS #FOOTER
\*------------------------------------*/
.footer {
  background-color: #2e3749;
  padding: 20px 0 70px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
@media (max-width: 768px) {
  .footer {
    padding: 20px 0 50px 0;
  }
}
@media (max-width: 560px) {
  .footer {
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .footer .d-flex {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.footer__copy > * {
  display: inline-block;
}
.footer__copy > *:not(:last-child) {
  margin-right: 30px;
}
.footer__copy a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.footer__copy a:hover {
  color: #fff !important;
}
@media (max-width: 768px) {
  .footer__copy span {
    display: block;
    margin-bottom: 10px;
  }
}

.footer__brand {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position: relative;
  min-width: 80px;
}
.footer__brand:before {
  content: "";
  width: 80px;
  height: 1px;
  display: block;
  background-color: #fff;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 560px) {
  .footer__brand {
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
  }
  .footer__brand:before {
    display: none;
  }
}

.footer__logo {
  width: 185px;
  height: 37px;
}
@media (max-width: 560px) {
  .footer__logo {
    width: 124px;
    height: 25px;
  }
}

/*------------------------------------*\
	@COMPONENTS #COVER
\*------------------------------------*/
.cover {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  position: relative;
}
.cover > * {
  width: 50%;
}
@media (max-width: 767px) {
  .cover {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    text-align: center;
  }
  .cover > * {
    width: 100%;
  }
}

.cover--alt {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.cover__img {
  position: relative;
  min-height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
}
@media (max-width: 767px) {
  .cover__img {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: 560px) {
  .cover__img--zoom {
    background-position: 50% 90%;
    background-size: cover;
  }
}

.cover__quote {
  bottom: 30px;
  right: -30px;
  position: absolute;
  background-color: rgba(66, 69, 200, 0.9);
  width: 600px;
  color: #fff;
  padding: 20px;
  font-size: 14px;
}
.cover__quote i {
  display: block;
  margin-top: 30px;
}
.cover--alt .cover__quote {
  right: auto;
  left: -30px;
}
@media (max-width: 1234px) {
  .cover__quote {
    width: 100%;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .cover__quote {
    font-size: 12px;
    left: 0;
    position: static;
    margin-bottom: -20px;
    max-width: 400px;
    padding: 30px;
  }
}
@media (max-width: 560px) {
  .cover__quote {
    padding: 15px 40px;
  }
  .cover__quote i {
    margin-top: 20px;
  }
}

.cover__content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px;
  padding-left: 100px;
}

.cover__content.flex-box-start {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 30px;
  padding-left: 100px;
  padding-top: 5%;
}

.cover--alt .cover__content {
  padding-left: 30px;
  padding-right: 100px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
@media (max-width: 1234px) {
  .cover__content {
    padding-left: 60px;
  }
  .cover--alt .cover__content {
    padding: 50px 30px 30px;
  }
}
@media (max-width: 767px) {
  .cover__content {
    padding: 50px 30px 30px;
  }

  .cover__content.flex-box-start {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 50px 30px 30px;
  }
}

.cover__text {
  /* max-width: 340px; */
  font-size: 18px;
}
.cover__text p,
.cover__text ul {
  margin-bottom: 30px;
}
@media (max-width: 1234px) {
  .cover__text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .cover__text {
    font-size: 16px;
    margin: 0 auto;
  }
}
@media (max-width: 560px) {
  .cover__text .btn {
    width: 100%;
  }
}

.cover__title {
  font-size: 30px;
  color: #4245c8;
  margin-bottom: 30px;
}
@media (max-width: 1234px) {
  .cover__title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .cover__title {
    font-size: 25px;
  }
  .cover__title br {
    display: none;
  }
}

.bullets-container {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 800;
  right: 30px;
}

.cover-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.cover-bullets a {
  display: inline-block;
  width: 5px;
  height: 5px;
  padding: 0;
  border-radius: 50% !important;
  border: none;
  background-color: #bbb;
  margin: 6px 0;
}
.cover-bullets .active a {
  width: 10px;
  height: 10px;
  background-color: #8bc73b;
}
@media (max-width: 560px) {
  .cover-bullets {
    display: none;
  }
}

.cover-bullets--alt a {
  opacity: 0.4;
  background-color: #fff !important;
}
.cover-bullets--alt .active a {
  opacity: 1;
}

/*------------------------------------*\
	@COMPONENTS #LIST-LINKED
\*------------------------------------*/
.list-linked {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-align: left;
}
.list-linked li {
  position: relative;
  padding-left: 40px;
  min-height: 30px;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbAQMAAAC3GmYWAAAABlBMVEVCRchCRcivsqsUAAAAAXRSTlMAQObYZgAAACRJREFUCNdj+P///wOGBgYGBeIJ+wYgoQAnVFC5YFlSzAO5AABvIBv9hMcl/AAAAABJRU5ErkJggg==) left top no-repeat;
}
.list-linked li:not(:last-child) {
  padding-bottom: 22px;
}
.list-linked li:not(:last-child):after {
  content: "";
  width: 1px;
  height: calc(100% - 9px);
  display: block;
  background-color: #4245c8;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  position: absolute;
  top: 18px;
  left: 12px;
  box-sizing: content-box;
}
@media (max-width: 768px) {
  .list-linked {
    font-size: 14px;
  }
}

.list-linked--alt li {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbAQMAAAC3GmYWAAAABlBMVEWLxzuLxzt130pzAAAAAXRSTlMAQObYZgAAACRJREFUCNdj+P///wOGBgYGBeIJ+wYgoQAnVFC5YFlSzAO5AABvIBv9hMcl/AAAAABJRU5ErkJggg==) left top no-repeat;
}
.list-linked--alt li:not(:last-child):after {
  background-color: #8bc73b;
}

/*------------------------------------*\
    @COMPONENTS #MODAL
\*------------------------------------*/
.modal {
  text-align: center;
}

.modal-md {
  max-width: 600px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 25px 40px;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal-title {
  color: #4245c8;
  font-size: 28px;
}

.modal-body {
  padding-bottom: 40px;
}

.modal-footer {
  padding: 12px 40px;
  -webkit-justify-content: center;
          justify-content: center;
}
.modal-footer small {
  font-size: 12px;
  color: #888;
}

/*------------------------------------*\
    @COMPONENTS #RADIOS
\*------------------------------------*/
.radio-block {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 40px;
}
.radio-block label {
  color: #707070;
  background-color: #ddd;
  padding: 10px 40px;
}
.radio-block input[type="radio"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.radio-block input[type="radio"]:checked + label {
  background-color: #8bc73b;
  color: #fff;
  padding: 15px 50px;
}

/*------------------------------------*\
	@COMPONENTS #LINKS
\*------------------------------------*/
.back-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;
  color: #999;
}
.back-link .icon {
  width: 30px;
  height: 16px;
}
.back-link span {
  margin-left: 20px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 560px) {
  .main__header .back-link span {
    display: none;
  }
}
.back-link:hover {
  color: #8bc73b;
}
.back-link:hover span {
  margin-left: 30px;
}
@media (max-width: 560px) {
  .back-link {
    font-size: 18px;
  }
}

/*------------------------------------*\
	@COMPONENTS #BLOCK
\*------------------------------------*/
.block {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
.block ul {
  list-style: none;
}
.block ul ul {
  margin-bottom: 0;
}
@media (max-width: 560px) {
  .block {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 14px;
  }
}

.block__title {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Avenir Next Bold", sans-serif;
  margin-bottom: 30px !important;
}
@media (max-width: 560px) {
  .block__title {
    font-size: 14px;
    margin-bottom: 25px !important;
  }
}

.block__list {
  margin-bottom: 30px;
  padding-left: 0;
}
.block__list:last-child {
  margin-bottom: 0;
}
.block__list > li:not(:last-child) {
  margin-bottom: 30px;
}

/*------------------------------------*\
	@TRUMPS #HELPERS
\*------------------------------------*/
.full {
  width: 100%;
}

.clean {
  margin: 0;
  padding: 0;
}

.visuallyhidden {
  position: absolute;
  left: -999em;
}

.invisible {
  visibility: hidden !important;
}

.no-transition,
.no-transition * {
  transition: none !important;
}

.amp {
  font-family: Baskerville, Palatino, "Palatino Linotype", "Book Antiqua", serif;
  font-size: 1.4em;
  font-style: italic;
  font-weight: normal;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.flat-bottom {
  padding-bottom: 0 !important;
}

.nowrap {
  white-space: nowrap !important;
}

.unselectable {
  -webkit-user-select: none;
  user-select: none;
}

/*------------------------------------*\
	@TRUMPS #LAYOUT
\*------------------------------------*/
@media (max-width: 992px) {
  .row {
    margin: 0 -15px;
  }
  .row.form-group {
    margin-bottom: 1rem;
  }
  .row > * {
    padding: 0 15px;
  }
}
.spaced1x {
  margin-bottom: 10px !important;
}

.spaced2x {
  margin-bottom: 20px !important;
}

.spaced3x {
  margin-bottom: 30px !important;
}

.spaced4x {
  margin-bottom: 40px !important;
}

.spaced5x {
  margin-bottom: 50px !important;
}

.spaced6x {
  margin-bottom: 60px !important;
}

.spaced7x {
  margin-bottom: 70px !important;
}

.spaced8x {
  margin-bottom: 80px !important;
}

.spaced9x {
  margin-bottom: 90px !important;
}

.spaced10x {
  margin-bottom: 100px !important;
}

/*------------------------------------*\
	@TRUMPS #PRINT
\*------------------------------------*/
/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/3c3c5e64604209a4d63e1e4c48dd245d45fadfd9/css/main.css#L200-L234
 */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /**
   * DonÔÇÖt show links that are fragment identifiers, or use the `javascript:`
   * pseudo protocol.
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
}

.calculator-box {
  padding: 20px;
  background-color: #f5f5f5b5;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
}

/* Slider  */
.no-ui-slider {
  width: 100%;
}

.no-ui-slider .slider-labels {
  list-style: none;
  padding: 0;
  margin-top: 5px;
  margin-left: -3px;
  width: 100%;
  text-align: left;
  font-size: 13px;
}

.no-ui-slider .slider-labels li {
  color: #d3d3d3;
  display: inline;
  float: left;
  cursor: pointer;
}

.no-ui-slider .slider-labels li:nth-last-child(1) {
  position: absolute;
  margin-left: -3px;
  width: 25px;
}

.no-ui-slider .slider-labels li:hover {
  color: #333333;
}

.no-ui-slider .slider-labels li.active {
  color: #333333;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  box-sizing: border-box;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
  cursor: move;
}
.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10;
}
.noUi-stacking + .noUi-origin {
  /* Fix stacking order in IE7, which incorrectly
   creates a new context for the origins. */
  *z-index: -1;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 10px !important;
}
.noUi-horizontal .noUi-handle {
  width: 20px !important  ;
  height: 20px !important ;
  left: -13px;
  top: -7px;
  border-radius: 100%;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 20px;
  height: 20px;
  left: -10px;
  top: -6px;
  border-radius: 100%;
}
.noUi-vertical.noUi-extended {
  padding: 15px 0;
}
.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}

/* Styling;
 */
.noUi-background {
  background: #fafafa;
  box-shadow: inset 0 1px 1px #f0f0f0;
}
.noUi-connect {
  background: #8bc73b !important;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  transition: background 450ms;
}
.noUi-origin {
  border-radius: 10px;
}
.noUi-target {
  border-radius: 10px;
  border: 1px solid #d3d3d3;
}
.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #bbb;
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}
.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  background: #fff;
  cursor: default;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  display: block;
  position: absolute;
  height: 14px;
  background: none !important;
  width: 1px;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid lightgray;
  outline: 0;
  font-size: 16px;
  color: #4943c1;
  font-weight: bold;
  padding: 0px !important;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::-webkit-input-placeholder {
  color: transparent;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
  cursor: text;
  top: 20px;
}

label,
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #9b9b9b;
}

.form__field:focus ~ .form__label {
  color: #009788;
}

.form__field:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid #009788;
}

.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.flex-column {
  text-align: center;
}

/* Checkbox */

.React__checkbox--input {
  display: none;
}

.React__checkbox--input + span {
  display: -webkit-flex;
  display: flex;
  width: 30px;
  height: 30px;
  background: white;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  margin: 5px;
  box-sizing: border-box;
  transition: all 150ms;
}
.React__checkbox--input:checked + span {
  background: #8bc73b;
}
.React__checkbox--input:checked + span::before {
  content: "\2713";
  font-family: "Material Icons";
  font-size: 40px;
  color: white;
}
.React__checkbox--input:disabled + span {
  background: rgba(0, 0, 0, 0.35);
}
.checkboxLabel {
  top: inherit;
  position: relative;
}
.calculator_Title,
.calculator_subtitles {
  color: #4943c1;
  font-weight: bold;
}
.calculator_Title {
  font-size: 30px;
}
.calculator_subtitles {
  font-size: 16px;
}

p.italic {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
  color: #4943c1;
}

.push {
  margin-left: auto;
}

/* DropDown */

.dropdown {
  position: relative;
}
.dropdown.open > .dropdown-menu {
  display: block;
}
.dropdown .dropdown-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: #000;
  background: none;
}
.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  text-align: left;
  list-style: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.dropdown .dropdown-menu li {
  cursor: pointer;
}
.dropdown .dropdown-menu li :hover {
  background: #ddd;
}
.dropdown .dropdown-menu li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.slider.slider--filter {
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
}

.flexbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
}

.flexbox-container > aside {
  padding: 10px;
  width: 50%;
  text-align: left;
}

.flexbox-container > aside.flexbox-label {
  width: 60%;
}

.flexbox-container > aside.flexbox-input {
  width: 40%;
}

.flexbox-container > aside.flex-checkbox {
  width: 15%;
}
.flexbox-container > aside.flex-checkbox-label {
  width: 85%;
}

.flexbox-container > aside.flexbox-label-checkbox {
  width: 75%;
}

.flexbox-container > aside.flexbox-input-checkbox {
  width: 25%;
}

.flexbox-container.flexbox-center {
  text-align: center !important;
}

.flexbox-container.flexbox-center > aside {
  text-align: center !important;
}
.flexbox-calculator {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  padding: 5px;
}
.padding-0px {
  padding: 0px;
}

@media screen and (max-width: 1199px) {
  .calculator-box {
    padding: 0;
  }
}
@media screen and (max-width: 1000px) {
  .calculator_subtitles {
    font-size: 14px;
  }
  .flexbox-container > aside.flex-checkbox-label {
    width: 75%;
  }

  .flexbox-container > aside.flex-checkbox {
    width: 25%;
  }
}

@media screen and (max-width: 900px) {
  .flexbox-container.flexbox-center {
    display: block;
  }
  .flexbox-container.flexbox-center > aside {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .flexbox-container.flexbox-center {
    display: -webkit-flex;
    display: flex;
  }
  .flexbox-container.flexbox-center > aside {
    width: 50%;
  }
  .form__field {
    font-size: 14px;
  }
  .calculator_Title {
    font-size: 25px;
  }
}

@media screen and (max-width: 500px) {
  .flexbox-container.flexbox-center {
    display: -webkit-flex;
    display: flex;
  }
  .flexbox-container.flexbox-center > aside {
    width: 50%;
  }
}
.flexbox-container > aside.flexbox-label.full-width {
  width: 100%;
}

.full-width .form__field {
  width: auto;
  max-width: 30%;
}

.calculator_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px !important;
}

.icon_phone_info {
  width: 11px;
  height: 20px;
  margin-right: 5px;
}
.header_info-hide-sm {
  color: white;
  font-size: 14px;
  font-weight: normal;
}
.margin-bottom-10 {
  margin-bottom: 5px !important;
}

.main {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .main {
    margin-top: 70px;
  }
}

.footerLink {
  color: #bbbbbb !important;
  font-weight: normal;
  text-decoration: underline;
}

.footerLink:hover {
  text-decoration: none !important;
}

.cover__text {
  max-width: 90%;
}

.cover__text p {
  text-align: left;
}

.footer {
  font-size: 16px;
  font-family: "Avenir Next Regular", sans-serif;
}

@media (max-width: 560px) {
  .footer {
    text-align: left;
    font-size: 12px;
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

.blue-asterisk {
  color: #4245c8;
}

.showOnMobile {
  display: none;
  width: 213px;
}

@media (max-width: 560px) {
  .showOnMobile {
    display: block;
    width: auto;
  }
  /* section.product>div.cover__img {
		display: none;
	} */
}

.hideOnMobile {
  display: block;
  width: 213px;
}

@media (max-width: 560px) {
  .hideOnMobile {
    display: none;
  }
}

span.btn-section {
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: center;
          align-content: center;
  color: #fff;
  padding-top: 10%;
}

.icon-btn {
  text-align: center;
  margin: 0 auto;
  background-color: transparent;
  background-color: initial;
  border: 5px solid #8bc73b !important;
  width: 75px;
  height: 45px;
  margin-bottom: 20px;
}

.play-btn {
  width: 25px;
}

.btn-overlay {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.paragrapSize {
  font-size: 16px;
}

#product span.btn-section {
  display: -webkit-flex;
  display: flex;
}

#example span.btn-section {
  display: none;
}

@media screen and (min-width: 993px) {
  span.btn-section {
    min-height: 850px;
  }
}

@media screen and (max-width: 992px) {
  span.btn-section {
    -webkit-flex-wrap: inherit;
            flex-wrap: inherit;
    min-height: 650px;
  }
}

@media screen and (max-width: 560px) {
  #example span.btn-section {
    display: -webkit-flex;
    display: flex;
  }
  /* #product span.btn-section {
		display: none;
	} */
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.modal-fullScreen {
  position: fixed;
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  padding: 0;
}

.modal-dialog-video {
  padding-top: 350px;
}

.modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-title {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}

.modal-body {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  font-weight: 300;
  padding-bottom: 40px;
  padding-top: 40px;
}

.modal-footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  padding: 10px;
  background: #f1f3f5;
}

.videoIframe {
  max-width: 700px;
  max-height: 395px;
}

.modal-body {
  top: 30%;
}

@media screen and (max-width: 768px) {
  .videoIframe {
    max-width: 530px;
    max-height: 300px;
  }
  .modal-body {
    top: 30%;
  }
}

@media screen and (max-width: 567px) {
  .videoIframe {
    max-width: 300px;
    max-height: 165px;
  }
}

@media screen and (max-width: 400px) {
  .videoIframe {
    max-width: 225px;
    max-height: 130px;
  }
}

.buttonFooterStyle {
  font-style: italic;
  font-size: 12px;
  padding-top: 10px;
}

.cookie-modal-footer-row {
  width: 100%;
}

.header-alert-investor {
  margin-bottom:3%
}

.header-info-banner,
.header-ribbon-banner {
  background-color: #000000;
  width: 100%;
  position: fixed;
  z-index: 11000 !important;
}

.header-info-text {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
}

@media (max-width: 767px) {
  .header-info-mob-5 {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .header-info-text {
    font-size: 10px;
  }

  .header-info-text-left {
    text-align: left;
  }

  .header-info-text-center {
    text-align: center;
  }

  .header-info-text-right {
    text-align: right;
  }

  .header-info-text-right {
    text-align: right;
  }
}

.ribbon:hover {
  color: #4245c8 !important;
}

/*FCA Note*/
.fca_header {
  text-decoration: underline;
  font-weight: 700;
}

@media (max-width: 420px) {
  .safari-based {
    font-family: "Arial", sans-serif;
  }
  .header-alert-investor {
    margin-bottom: 13%;
  }
  
}
.header-investor {
  padding-top: 20px
}
@-moz-document url-prefix() {
  .header-investor {
    padding-top: 50px;
  }
}


@media (max-width: 1024px) {

  .header--alt-investor,
  .header-top-padding {
    padding-top: 70px !important;
  }

}



/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css */
a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }
}

* {
  box-sizing: border-box;
}

:after,
:before {
  box-sizing: border-box;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
}

p {
  margin: 0 0 10px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-md-6 {
    float: left;
  }

  .col-md-6 {
    width: 50%;
  }
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.container:after,
.container:before,
.modal-footer:after,
.modal-footer:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.container:after,
.modal-footer:after,
.row:after {
  clear: both;
}

/*! CSS Used from: https://app.lendwise.com/styles/styles.css */
body * {
  box-sizing: border-box;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

h3 {
  line-height: 1;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: 400;
}

h3:first-child {
  margin-top: 0;
}

h3 {
  font-size: 20px;
  font-family: "Avenir Next Demi", sans-serif;
  margin-bottom: 15px;
}

p {
  margin-bottom: 30px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  outline: none !important;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.container {
  width: 100% !important;
  max-width: 1234px !important;
}

.modal {
  z-index: 3000;
}

.modal-backdrop {
  z-index: 2999;
}

.modal--md .modal-dialog {
  width: 100%;
}

.modal--md .modal-dialog {
  max-width: 800px;
}

@media (min-width: 1233px) {
  .container {
    width: 1234px;
  }
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }
}

/*! CSS Used from: https://app.lendwise.com/styles/custom.css */
.row-flex {
  display: -webkit-flex;
  display: flex;
}

.header_alert--text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  color: #444;
}

.row-flex {
  display: -webkit-flex;
  display: flex;
}

.cookie-modal {
  text-align: justify;
  color: #444;
}

.cookie-modal a {
  text-decoration: underline;
}

.cookie-modal p {
  margin-bottom: 10px;
}

.cookie-alert {
  position: fixed;
  width: 100%;
  z-index: 10000;
  border-top: 1px solid #aaaaaa;
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
  border: none;
}

.cookie-modal.in {
  display: -webkit-flex !important;
  display: flex !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;
}

.cookie-modal .cookie-modal-content {
  background-color: #fff;
  border: 0;
  padding: 5px;
  border-bottom: 6px solid #4a4a4a;
}

.cookie-modal {
  padding: 0;
}

.cookie-modal-body {
  padding: 10px 10px 5px 10px;
}

.cookie-modal-footer {
  padding: 0px 30px 0px 30px;
  border: none;
}

.cookie-modal h3,
.cookie-modal p {
  font-size: 16px !important;
}

@media (max-height: 768px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 13px !important;
  }
}

@media (max-height: 480px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 11px !important;
  }
}

@media (max-height: 667px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 12px !important;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Avenir Next Regular";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot");
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot");
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-modal-footer {
  padding: 10px 0 10px 0 !important;
}

.cookies-alert-container {
  padding-bottom: 50px !important;
  padding-left: 0 !important;
}

ul.cookieList li:last-child {
  padding-bottom: 0px;
}

@media (max-width: 657px) {
  ul.cookieList li:last-child {
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .cookie-modal-footer-alert {
    padding: 50px 0 50px 0 !important;
  }

  .cookies-alert-container {
    padding-bottom: 0px !important;
  }
}
/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css */
input {
  margin: 0;
  font: inherit;
  color: inherit;
}

input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    box-shadow: none !important;
  }

  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }
}

* {
  box-sizing: border-box;
}

:after,
:before {
  box-sizing: border-box;
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
}

p {
  margin: 0 0 10px;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}

input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -70%);
}

.modal.test .modal-dialog-video {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(3, 20);
  transform: translate(20, 0);
}

.modal-dialog-video {
  max-width: 800px;
}

.modal-dialog-video {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
}

.modal-dialog-video {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  top: 70%;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-content-video {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.modal-footer:after,
.modal-footer:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.modal-footer:after,
.row:after {
  clear: both;
}

/*! CSS Used from: https://app.lendwise.com/styles/styles.css */
body * {
  box-sizing: border-box;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

h3 {
  line-height: 1;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: 400;
}

h3:first-child {
  margin-top: 0;
}

h3 {
  font-size: 20px;
  font-family: "Avenir Next Demi", sans-serif;
  margin-bottom: 15px;
}

p,
ul {
  margin-bottom: 30px;
}

ul:last-child {
  margin-bottom: 0;
}

ul {
  list-style-type: disc;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline;
}

input:focus {
  outline: none;
}

.form-control {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px !important;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease 0s;
}

@media (max-width: 480px) {
  .form-control {
    height: 40px;
    line-height: 40px;
  }
}

input,
.form-control {
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: none;
  box-shadow: none !important;
  font-family: "Avenir Next Regular", sans-serif;
  font-size: 22px;
  padding: 0 !important;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 0;
}

@media (max-width: 768px) {

  input,
  .form-control {
    font-size: 20px;
  }
}

@media (max-width: 480px) {

  input,
  .form-control {
    font-size: 16px;
  }
}

input:hover,
input:focus,
.form-control:hover,
.form-control:focus {
  border-color: #4245c8;
}

input:invalid,
.form-control:invalid {
  box-shadow: none;
}

input::-ms-clear,
.form-control::-ms-clear {
  display: none;
}

input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder,
.form-control:-moz-placeholder {
  color: #999;
  opacity: 1;
}

input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #999;
}

label {
  font-weight: normal;
  font-family: "Avenir Next Regular", sans-serif;
}

.required {
  padding-right: 10px;
}

.required:after {
  content: " *";
  color: #e8425e;
}

.checkbox-box {
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 14px;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: normal;
}

input[type="checkbox"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type="checkbox"]+label {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
}

input[type="checkbox"]+label:before,
input[type="checkbox"]+label:after {
  position: absolute;
  top: 4px;
  transition: all 0.3s ease 0s;
}

.checkbox-box input[type="checkbox"]+label:before,
.checkbox-box input[type="checkbox"]+label:after {
  top: 0;
}

input[type="checkbox"]+label:before {
  content: "";
  width: 18px;
  height: 18px;
  display: block;
  border: 2px solid #ddd;
  left: 0;
}

input[type="checkbox"]+label:after {
  content: "\2713";
  color: #8bc73b;
  font-family: Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  left: 4px;
  opacity: 0;
}

input[type="checkbox"]+label:hover {
  color: #4245c8;
}

input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.modal {
  z-index: 3000;
}

.modal--md .modal-dialog {
  width: 100%;
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

/*! CSS Used from: https://app.lendwise.com/styles/custom.css */
@media (max-width: 580px) {
  input.form-control::-webkit-input-placeholder {
    font-size: 13px;
  }

  input.form-control::placeholder {
    font-size: 13px;
  }
}

.tooltip_ui {
  position: relative;
}

.tooltip_ui-trigger {
  display: inline-block;
  width: 100%;
}

.cookie-modal {
  text-align: justify;
  color: #444;
}

.cookie-modal p {
  margin-bottom: 10px;
}

.cookie-modal .checkbox-box {
  border: none;
  padding: 5px 0;
}

.cookie-modal .checkbox-box label {
  font-weight: 500;
  font-size: 16px;
  font-family: "Avenir Next Bold", sans-serif;
}

.cookie-modal .checkbox-box label:hover {
  color: #444;
}

.cookieList {
  list-style-type: none;
  padding-left: 0px;
}

.cookieList li {
  padding-bottom: 5px;
  margin: none;
}

.cookie-modal.in {
  display: -webkit-flex !important;
  display: flex !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;
}

.cookie-modal .cookie-modal-content {
  background-color: #fff;
  border: 0;
  padding: 5px;
  border-bottom: 6px solid #4a4a4a;
}

.cookie-modal {
  padding: 0;
}

.cookie-modal-body {
  padding: 3% 5% 0% 5%;
}

.cookie-modal-footer {
  padding: 0px 10px 5px 10px;
  border: none;
}

.cookie-modal h3,
.cookie-modal p,
.cookie-modal li,
.cookie-modal li label {
  font-size: 16px !important;
}

@media (max-height: 768px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 13px !important;
  }
}

@media (max-height: 480px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 11px !important;
  }
}

@media (max-height: 667px) {

  .cookie-modal h3,
  .cookie-modal p,
  .cookie-modal li,
  .cookie-modal li label {
    font-size: 12px !important;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Avenir Next Regular";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot");
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot");
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Bold";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.eot");
  src: local("Avenir Next LT Pro Bold"), local("AvenirNextLTPro-Bold"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.cookie-preferences {
  overflow-x: hidden;
  overflow-y: auto;
}
/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css */
@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    box-shadow: none !important;
  }
}

* {
  box-sizing: border-box;
}

:after,
:before {
  box-sizing: border-box;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

/*! CSS Used from: https://app.lendwise.com/styles/styles.css */
body * {
  box-sizing: border-box;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

.modal-backdrop {
  z-index: 2999;
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.btn--ghost {
  position: relative;
  text-align: center;
}
.btn--ghost,
.btn--ghost:focus {
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #fff;
  border-color: currentColor;
}
.btn--ghost.btn--grey,
.btn--ghost.btn--grey:focus {
  color: #999;
}
.btn--ghost.btn--grey:hover,
.btn--ghost.btn--grey:focus:hover {
  background-color: #999 !important;
  color: #fff;
}

.btn--alt,
.btn--alt:focus {
  background-color: #8bc73b;
  color: #fff;
}
.btn--alt:hover,
.btn--alt:focus:hover {
  background-color: #70a12e;
}

.btn--primary,
.btn--primary:focus {
  background-color: #4245c8;
  color: #fff;
}
.btn--primary:hover,
.btn--primary:focus:hover {
  background-color: #3033a7;
}
