.main {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .main {
    margin-top: 70px;
  }
}

.footerLink {
  color: #bbbbbb !important;
  font-weight: normal;
  text-decoration: underline;
}

.footerLink:hover {
  text-decoration: none !important;
}

.cover__text {
  max-width: 90%;
}

.cover__text p {
  text-align: left;
}

.footer {
  font-size: 16px;
  font-family: "Avenir Next Regular", sans-serif;
}

@media (max-width: 560px) {
  .footer {
    text-align: left;
    font-size: 12px;
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

.blue-asterisk {
  color: #4245c8;
}

.showOnMobile {
  display: none;
  width: 213px;
}

@media (max-width: 560px) {
  .showOnMobile {
    display: block;
    width: auto;
  }
  /* section.product>div.cover__img {
		display: none;
	} */
}

.hideOnMobile {
  display: block;
  width: 213px;
}

@media (max-width: 560px) {
  .hideOnMobile {
    display: none;
  }
}

span.btn-section {
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: center;
  align-content: center;
  color: #fff;
  padding-top: 10%;
}

.icon-btn {
  text-align: center;
  margin: 0 auto;
  background-color: transparent;
  background-color: initial;
  border: 5px solid #8bc73b !important;
  width: 75px;
  height: 45px;
  margin-bottom: 20px;
}

.play-btn {
  width: 25px;
}

.btn-overlay {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.paragrapSize {
  font-size: 16px;
}

#product span.btn-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#example span.btn-section {
  display: none;
}

@media screen and (min-width: 993px) {
  span.btn-section {
    min-height: 850px;
  }
}

@media screen and (max-width: 992px) {
  span.btn-section {
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    min-height: 650px;
  }
}

@media screen and (max-width: 560px) {
  #example span.btn-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  /* #product span.btn-section {
		display: none;
	} */
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.modal-fullScreen {
  position: fixed;
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  padding: 0;
}

.modal-dialog-video {
  padding-top: 350px;
}

.modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-title {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}

.modal-body {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  font-weight: 300;
  padding-bottom: 40px;
  padding-top: 40px;
}

.modal-footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  padding: 10px;
  background: #f1f3f5;
}

.videoIframe {
  max-width: 700px;
  max-height: 395px;
}

.modal-body {
  top: 30%;
}

@media screen and (max-width: 768px) {
  .videoIframe {
    max-width: 530px;
    max-height: 300px;
  }
  .modal-body {
    top: 30%;
  }
}

@media screen and (max-width: 567px) {
  .videoIframe {
    max-width: 300px;
    max-height: 165px;
  }
}

@media screen and (max-width: 400px) {
  .videoIframe {
    max-width: 225px;
    max-height: 130px;
  }
}

.buttonFooterStyle {
  font-style: italic;
  font-size: 12px;
  padding-top: 10px;
}

.cookie-modal-footer-row {
  width: 100%;
}

.header-alert-investor {
  margin-bottom:3%
}

.header-info-banner,
.header-ribbon-banner {
  background-color: #000000;
  width: 100%;
  position: fixed;
  z-index: 11000 !important;
}

.header-info-text {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
}

@media (max-width: 767px) {
  .header-info-mob-5 {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .header-info-text {
    font-size: 10px;
  }

  .header-info-text-left {
    text-align: left;
  }

  .header-info-text-center {
    text-align: center;
  }

  .header-info-text-right {
    text-align: right;
  }

  .header-info-text-right {
    text-align: right;
  }
}

.ribbon:hover {
  color: #4245c8 !important;
}

/*FCA Note*/
.fca_header {
  text-decoration: underline;
  font-weight: 700;
}

@media (max-width: 420px) {
  .safari-based {
    font-family: "Arial", sans-serif;
  }
  .header-alert-investor {
    margin-bottom: 13%;
  }
  
}
.header-investor {
  padding-top: 20px
}
@-moz-document url-prefix() {
  .header-investor {
    padding-top: 50px;
  }
}


@media (max-width: 1024px) {

  .header--alt-investor,
  .header-top-padding {
    padding-top: 70px !important;
  }

}


