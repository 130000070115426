/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css */
a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
}

p {
  margin: 0 0 10px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .col-md-6 {
    float: left;
  }

  .col-md-6 {
    width: 50%;
  }
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.container:after,
.container:before,
.modal-footer:after,
.modal-footer:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.container:after,
.modal-footer:after,
.row:after {
  clear: both;
}

/*! CSS Used from: https://app.lendwise.com/styles/styles.css */
body * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-moz-selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #4245c8;
  color: #fff;
  text-shadow: none;
}

h3 {
  line-height: 1;
  font-family: "Avenir Next Regular", sans-serif;
  font-weight: 400;
}

h3:first-child {
  margin-top: 0;
}

h3 {
  font-size: 20px;
  font-family: "Avenir Next Demi", sans-serif;
  margin-bottom: 15px;
}

p {
  margin-bottom: 30px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  outline: none !important;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.container {
  width: 100% !important;
  max-width: 1234px !important;
}

.modal {
  z-index: 3000;
}

.modal-backdrop {
  z-index: 2999;
}

.modal--md .modal-dialog {
  width: 100%;
}

.modal--md .modal-dialog {
  max-width: 800px;
}

@media (min-width: 1233px) {
  .container {
    width: 1234px;
  }
}

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }
}

/*! CSS Used from: https://app.lendwise.com/styles/custom.css */
.row-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header_alert--text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  color: #444;
}

.row-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cookie-modal {
  text-align: justify;
  color: #444;
}

.cookie-modal a {
  text-decoration: underline;
}

.cookie-modal p {
  margin-bottom: 10px;
}

.cookie-alert {
  position: fixed;
  width: 100%;
  z-index: 10000;
  border-top: 1px solid #aaaaaa;
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
  border: none;
}

.cookie-modal.in {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;
}

.cookie-modal .cookie-modal-content {
  background-color: #fff;
  border: 0;
  padding: 5px;
  border-bottom: 6px solid #4a4a4a;
}

.cookie-modal {
  padding: 0;
}

.cookie-modal-body {
  padding: 10px 10px 5px 10px;
}

.cookie-modal-footer {
  padding: 0px 30px 0px 30px;
  border: none;
}

.cookie-modal h3,
.cookie-modal p {
  font-size: 16px !important;
}

@media (max-height: 768px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 13px !important;
  }
}

@media (max-height: 480px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 11px !important;
  }
}

@media (max-height: 667px) {

  .cookie-modal h3,
  .cookie-modal p {
    font-size: 12px !important;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "Avenir Next Regular";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot");
  src: local("Avenir Next LT Pro Regular"), local("AvenirNextLTPro-Regular"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next Demi";
  src: url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot");
  src: local("Avenir Next LT Pro Demi"), local("AvenirNextLTPro-Demi"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.eot#iefix") format("embedded-opentype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("https://app.lendwise.com/fonts/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-modal-footer {
  padding: 10px 0 10px 0 !important;
}

.cookies-alert-container {
  padding-bottom: 50px !important;
  padding-left: 0 !important;
}

ul.cookieList li:last-child {
  padding-bottom: 0px;
}

@media (max-width: 657px) {
  ul.cookieList li:last-child {
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .cookie-modal-footer-alert {
    padding: 50px 0 50px 0 !important;
  }

  .cookies-alert-container {
    padding-bottom: 0px !important;
  }
}