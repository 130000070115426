.btn--ghost {
  position: relative;
  text-align: center;
}
.btn--ghost,
.btn--ghost:focus {
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #fff;
  border-color: currentColor;
}
.btn--ghost.btn--grey,
.btn--ghost.btn--grey:focus {
  color: #999;
}
.btn--ghost.btn--grey:hover,
.btn--ghost.btn--grey:focus:hover {
  background-color: #999 !important;
  color: #fff;
}
